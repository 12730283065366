import apiAnnouncements from '@/domains/admin/api/announcement.api';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type AdminAnnouncementInterface from '@/domains/admin/typescript/AdminAnnouncementInterface';
import type AdminAnnouncementPageDisplayEnum from '@/domains/admin/typescript/AdminAnnouncementPageDisplayEnum';

export default defineStore('adminAnnoucement', () => {
    const announcements = ref<AdminAnnouncementInterface[]>();
    const selectedAnnouncement = ref<AdminAnnouncementInterface>();

    const totalAnnouncements = computed(() => announcements.value?.length ?? 0);
    const hasAnnouncementSelected = computed<boolean>(() => selectedAnnouncement.value !== undefined);

    function selectAnnouncementById(announcementId: AdminAnnouncementInterface['id']): void {
        selectedAnnouncement.value = announcements.value?.find((announcement) => announcement.id === announcementId);
    }

    function isAnnouncementSelected(announcement: AdminAnnouncementInterface): boolean {
        return selectedAnnouncement.value?.id === announcement.id;
    }

    function unselectAnnouncement(): void {
        selectedAnnouncement.value = undefined;
    }

    async function fetchAnnouncements(page = 1, limit = 10) {
        const { entities } = await apiAnnouncements.getAnnouncements({ page, limit });
        announcements.value = entities;
    }

    async function fetchAnnouncementsByPage(displayPage: AdminAnnouncementPageDisplayEnum) {
        announcements.value = await apiAnnouncements.getAnnouncementsByPage(displayPage);
    }

    function getAnnouncementById(id: number): AdminAnnouncementInterface | undefined {
        return announcements.value?.find((announcement) => announcement.id === id);
    }

    const getActiveAnnouncements = computed<AdminAnnouncementInterface[] | undefined>(() => {
        return announcements.value?.filter((announcement) => announcement.isActivated);
    });

    function removeAnnouncement(id: number): void {
        const index = announcements.value?.findIndex((announcement) => announcement.id === id);
        if (index !== undefined && index !== -1) {
            announcements.value?.splice(index, 1);
        }
    }

    function $reset() {
        announcements.value = undefined;
        selectedAnnouncement.value = undefined;
    }

    return {
        announcements,
        selectedAnnouncement,
        hasAnnouncementSelected,
        totalAnnouncements,
        selectAnnouncementById,
        isAnnouncementSelected,
        unselectAnnouncement,
        getAnnouncementById,
        getActiveAnnouncements,
        removeAnnouncement,

        fetchAnnouncements,
        fetchAnnouncementsByPage,

        $reset,
    };
});
