import materials from '@/domains/scan/data/materials.json';
import pattern from '@/domains/scan/data/pattern.json';
import cutLength from '@/domains/scan/data/cutLength.json';
import heelPad from '@/domains/scan/data/heelPad.json';
import covering from '@/domains/scan/data/covering.json';
import metatarsalPad from '@/domains/scan/data/metatarsalPad.json';
import fullLengthPad from '@/domains/scan/data/fullLengthPad.json';
import solePresets from '@/domains/scan/data/solePresets.json';
import jsonConfigurationAdapter from '@/domains/scan/services/jsonconfigurationAdapter';
import i18n from '@/translations';
import createNumberArray from '@/domains/common/services/utils/createNumberArray';
import arrayToSelectOption from '@/domains/common/services/utils/arrayToSelectOption';
import type { InferType } from 'yup';
import type configurationDataSchema from '@/domains/scan/validators/configurationData.schema';
import type ConfigurationInfosInterface from '@/domains/scan/typescript/interfaces/ConfigurationInfosInterface';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';
import type { SolePresetsInterface } from '@/domains/scan/typescript/interfaces/solePresetsInterface';

const { t } = i18n.global;

const jsonListMaterials: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(materials)),
);

const jsonPattern: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(pattern)),
);

const jsonCutLength: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(cutLength)),
);

const jsonCovering: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(covering)),
);

const jsonHeelPad: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(heelPad)),
);

const jsonMetatarsalPad: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(metatarsalPad)),
);

const jsonFullLengthPad: InferType<typeof configurationDataSchema> = jsonConfigurationAdapter(
    JSON.parse(JSON.stringify(fullLengthPad)),
);

const jsonSolePresets: Array<SolePresetsInterface> = JSON.parse(JSON.stringify(solePresets));

// function to avoid cross dependency with order store due to isMillingManufacture | isPrintManufacture - make it although reactive
const materialList = (isMillingManufacture: boolean, isPrintManufacture: boolean) =>
    !jsonListMaterials
        ? [{ value: '0', label: '' }]
        : jsonListMaterials
              .filter(
                  (mat: ConfigurationInfosInterface) =>
                      (mat.milling && isMillingManufacture) ||
                      (mat.printing && isPrintManufacture) ||
                      (!isMillingManufacture && !isPrintManufacture),
              )
              .map(
                  (mat: ConfigurationInfosInterface): SelectOptionInterface<string> => ({
                      value: mat.order,
                      label: t(`scan.material_list.label_${mat.order}`),
                  }),
              );

const patternList = !jsonPattern
    ? [{ value: '0', label: '' }]
    : jsonPattern.map((gab: ConfigurationInfosInterface) => ({
          value: gab.order,
          label: t(`scan.pattern_list.label_${gab.order}`),
      }));

const cutLengthList = !jsonCutLength
    ? [{ value: '0', label: '' }]
    : jsonCutLength.map((long: ConfigurationInfosInterface) => ({
          value: long.order,
          label: t(`scan.cut_length_list.label_${long.order}`),
      }));

const coveringList = !jsonCovering
    ? [{ value: '0', label: '' }]
    : jsonCovering.map((cover: ConfigurationInfosInterface) => ({
          value: cover.order,
          label: t(`scan.covering_list.label_${cover.order}`),
      }));

const heelPadList = !jsonHeelPad
    ? [{ value: '0', label: '' }]
    : jsonHeelPad.map((heelPadElem: ConfigurationInfosInterface) => ({
          value: heelPadElem.order,
          label: t(`scan.heel_pad_list.label_${heelPadElem.order}`),
      }));

const metatarsalPadList = !jsonMetatarsalPad
    ? [{ value: '0', label: '' }]
    : jsonMetatarsalPad.map((metaPad: ConfigurationInfosInterface) => ({
          value: metaPad.order,
          label: t(`scan.metatarsal_pad_list.label_${metaPad.order}`),
      }));

const fullLengthPadList = !jsonFullLengthPad
    ? [{ value: '0', label: '' }]
    : jsonFullLengthPad.map((lengthPad: ConfigurationInfosInterface) => ({
          value: lengthPad.order,
          label: t(`scan.full_length_pad_list.label_${lengthPad.order}`),
      }));

const soleList = jsonSolePresets.map((sole: SolePresetsInterface, index: number) => ({
    value: `${index}`,
    label: sole.PresetName,
}));

const shoeSizeList = arrayToSelectOption(
    createNumberArray({
        start: 20,
        stop: 50,
        step: 0.5,
    }),
);

export {
    materialList,
    patternList,
    cutLengthList,
    coveringList,
    heelPadList,
    metatarsalPadList,
    fullLengthPadList,
    soleList,
    jsonSolePresets,
    shoeSizeList,
};
