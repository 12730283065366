import ScanController from './ScanController';
import ScanWorld from './ScanWorld';
import Screenshot from '@/3d-app/commons/screenshot/Screenshot';
import { Vector3, Quaternion } from 'three';

let isScreenshotManagerReady: boolean = true;

/**
 * Manager used for scene screenshots.
 */
const ScreenshotManager = {
    /**
     * Capture a screenshot and return it as a Blob. Change this method for a different/multiple screenshots
     * @param width width of the captured image, in pixels
     * @param height height of the captured image, in pixels
     * @returns a promise that is resolved when the screenshot is captured, with the image as a Blob
     */
    getScreenshot(width: number = 512, height: number = 512): Promise<Blob> {
        if (!isScreenshotManagerReady) {
            return Promise.reject(new Error('ScreenshotManager not ready; wait for previous promise to end!'));
        }
        if (!ScanController.instance) {
            return Promise.reject(new Error('ScanController not ready!'));
        }
        isScreenshotManagerReady = false;

        const scanController = ScanController.instance;
        const { camera } = scanController;

        scanController.modeManager.elementMode.selectElementById(null); // Unselect selected element

        // Save camera othographic mode
        const isOrthographic = camera.getIsOrthographic();
        // Save camera transforms
        const originalPosition = camera.position.clone();
        const originalOrientation = camera.quaternion.clone();
        // Change camera transforms
        camera.setOrthographic(false);
        ScanController.instance?.camera?.homeCamera(
            ScanController.footSize * ScanController.SCALE_TO_M_FACTOR * ScanWorld.HOME_CAMERA_DISTANCE,
            new Vector3(),
            false,
        );
        camera.quaternion.copy(new Quaternion(0, 0, 0, 1));

        const currentSole = scanController.currentSoleMesh;
        const currentScan = scanController.currentScanMesh;
        const otherSole = scanController.getSoleMesh(!scanController.isRightFoot);
        const otherScan = scanController.getScanMesh(!scanController.isRightFoot);

        // Save alpha
        const currentSoleAlpha = currentSole.getOpacity();
        const currentScanAlpha = currentScan.getOpacity();
        const otherSoleAlpha = otherSole.getOpacity();
        const otherScanAlpha = otherScan.getOpacity();

        // Set alpha
        currentSole.setOpacity(1);
        currentScan.setOpacity(1);
        otherSole.setOpacity(1);
        otherScan.setOpacity(1);

        // Save attached
        const currentScanAttached = currentScan.attached;
        const otherScanAttached = otherScan.attached;

        // Detach attached
        currentScanAttached.removeFromParent();
        otherScanAttached.removeFromParent();

        return Screenshot.screenshot(
            width,
            height,
            scanController,
            camera,
            scanController.worldParams.webGLParameters,
            null,
            true,
            'image/png',
        ).finally(() => {
            // Restore attached
            currentScan.pivot.add(currentScanAttached);
            otherScan.pivot.add(otherScanAttached);

            // Restore alpha
            currentSole.setOpacity(currentSoleAlpha);
            currentScan.setOpacity(currentScanAlpha);
            otherSole.setOpacity(otherSoleAlpha);
            otherScan.setOpacity(otherScanAlpha);

            // Restore camera transforms
            camera.setOrthographic(isOrthographic);
            camera.position.copy(originalPosition);
            camera.quaternion.copy(originalOrientation);

            isScreenshotManagerReady = true;

            return Promise.resolve();
        });
    },
};

export default ScreenshotManager;
