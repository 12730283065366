import useAuthStore from '@/domains/authentication/store/auth.store';
import useSettingsStore from '@/domains/settings/store/settings.store';
import use3DStore from '@/domains/common/store/3d.store';
import useScanStore from '@/domains/scan/store/scan.store';
import useAdminUserStore from '@/domains/admin/store/adminUser.store';
import useAdminOfficeStore from '@/domains/admin/store/adminOffice.store';
import useAdminAnnouncementStore from '@/domains/admin/store/adminAnnouncement.store';
import useOrderStore from '@/domains/order/store/order.store';
import usePatientStore from '@/domains/patient/store/patient.store';
import StoreEnum from '@/domains/common/typescript/StoreEnum';
import { type Pinia, type Store, getActivePinia } from 'pinia';

interface ExtendedPinia extends Pinia {
    _s: Map<string, Store>;
}

export default function useResetStore() {
    const pinia = getActivePinia() as ExtendedPinia;

    const authStore = useAuthStore();
    const threeDStore = use3DStore();
    const scanStore = useScanStore();
    const settingsStore = useSettingsStore();
    const adminOfficeStore = useAdminOfficeStore();
    const adminUserStore = useAdminUserStore();
    const adminAnnouncementStore = useAdminAnnouncementStore();
    const orderStore = useOrderStore();
    const patientStore = usePatientStore();

    if (!pinia) {
        throw new Error('There is no active Pinia instance');
    }

    function resetAllStores() {
        authStore.$reset();
        threeDStore.$reset();
        scanStore.$reset();
        settingsStore.$reset();
        adminUserStore.$reset();
        adminOfficeStore.$reset();
        adminAnnouncementStore.$reset();
        orderStore.$reset();
        patientStore.$reset();
    }

    function resetStores(stores: string[] = []): void {
        if (stores.length === 0) {
            resetAllStores();
            return;
        }

        stores.forEach((store) => {
            switch (store) {
                case StoreEnum.AUTH:
                    authStore.$reset();
                    break;
                case StoreEnum.THREE_D:
                    threeDStore.$reset();
                    break;
                case StoreEnum.SCAN:
                    scanStore.$reset();
                    break;
                case StoreEnum.SETTINGS:
                    settingsStore.$reset();
                    break;
                case StoreEnum.ADMIN:
                    adminUserStore.$reset();
                    adminOfficeStore.$reset();
                    adminAnnouncementStore.$reset();
                    break;
                case StoreEnum.ORDER:
                    orderStore.$reset();
                    break;
                case StoreEnum.PATIENT:
                    patientStore.$reset();
                    break;
                default:
                    break;
            }
        });
    }

    return { resetStores, resetAllStores };
}
