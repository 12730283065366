<script setup lang="ts">
import HeaderLayout from '@/layouts/HeaderLayout.vue';
import RouteNameEnum from '@/router/routeNameEnum';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import { logout } from '@/domains/authentication/services/authenticate';
import IconArrowTopRightSquare from '@/domains/common/components/icons/IconArrowTopRightSquare.vue';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import MenuDropdown from '@/domains/common/components/MenuDropdown.vue';
import BaseButtonRouterLink from '@/domains/common/components/BaseButtonRouterLink.vue';
import BaseLink from '@/domains/common/components/BaseLink.vue';
import TheSelectOfficeHeader from '@/domains/authentication/components/TheSelectOfficeHeader.vue';
import useAuthStore from '@/domains/authentication/store/auth.store';
import RoleEnum from '@/domains/authentication/typescript/RoleEnum';
import { computed } from 'vue';

const authStore = useAuthStore();

const isAdminErgo = computed(() => authStore.isGranted(RoleEnum.ROLE_ADMIN_ERGO));
</script>
<template>
    <HeaderLayout>
        <template #navSection="{ isMenuDisabled }">
            <BaseButtonRouterLink
                :to="{ name: RouteNameEnum.AdminOffice }"
                :class="[
                    'transition-colors duration-300 ease-in-out',
                    isMenuDisabled && 'pointer-events-none opacity-50',
                ]"
                active-class="!text-primary !font-bold"
                :color="ButtonColorEnum.Menu"
                :label="$t('menu.office', isAdminErgo ? 2 : 1)"
            />
            <BaseButtonRouterLink
                :to="{ name: RouteNameEnum.AdminUser }"
                :class="[
                    'px-4 font-semibold transition-colors duration-300 ease-in-out',
                    isMenuDisabled && 'pointer-events-none opacity-50',
                ]"
                active-class="!text-primary !font-bold"
                :color="ButtonColorEnum.Menu"
                :label="$t('menu.user', 2)"
            />
            <BaseButtonRouterLink
                :to="{ name: RouteNameEnum.AdminAnnouncement }"
                :class="[
                    'px-4 font-semibold transition-colors duration-300 ease-in-out',
                    isMenuDisabled && 'pointer-events-none opacity-50',
                ]"
                active-class="!text-primary !font-bold"
                :color="ButtonColorEnum.Menu"
                :label="$t('menu.announcement')"
            />
        </template>
        <template #menuSection="{ isMenuLarge, isMenuDisabled }">
            <div :class="[isMenuLarge ? 'pr-0' : 'pr-14', 'flex items-center']">
                <MenuDropdown :class="[isMenuDisabled && 'pointer-events-none opacity-50', 'min-w-32']">
                    <template #buttonBody>
                        <span>{{ $t('menu.my_account') }}</span>
                    </template>
                    <template #dropdownList>
                        <BaseButton
                            :label="$t('menu.logout')"
                            :color="ButtonColorEnum.Menu"
                            @click="logout({ shouldLoginRedirect: true })"
                        />
                    </template>
                </MenuDropdown>
                <BaseLink
                    link="https://www.ergorecherche.fr/aide_podofit/"
                    :class="[
                        'px-4 font-semibold transition-colors duration-300 ease-in-out hover:text-secondary',
                        isMenuDisabled && 'pointer-events-none opacity-50',
                    ]"
                    :color="ButtonColorEnum.Menu"
                    :label="$t('menu.help')"
                    :icon="IconArrowTopRightSquare"
                />
            </div>
            <TheSelectOfficeHeader :expanded-menu="isMenuLarge" />
        </template>
    </HeaderLayout>
</template>
