import AlignSoleTool from './AlignSoleTool';
import ScanController from '@/3d-app/scan/ScanController';
import Mode from '@/3d-app/commons/Mode';
import ModeEnum from '@/3d-app/scan/ModeEnum';

/**
 * Mode for adjusting the current sole position.
 * The user can only move it along z axis, with left click.
 * The user need to click on the sole to move it.
 * During movement, camera transformation is disabled.
 */
class AlignSoleMode extends Mode {
    private _alignSoleTool: AlignSoleTool;

    private _mouseDownEventListener: EventListener;

    private _mouseUpEventListener: EventListener;

    private _mouseMoveEventListener: EventListener;

    constructor() {
        super(ModeEnum.ALIGN_SOLE);

        this._alignSoleTool = new AlignSoleTool();
        this._mouseDownEventListener = (event: Event) => this.onMouseDownEvent(event as MouseEvent);
        this._mouseUpEventListener = (event: Event) => this.onMouseUpEvent(event as MouseEvent);
        this._mouseMoveEventListener = (event: Event) => this.onMouseMoveEvent(event as MouseEvent);
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Add event listeners
        scanController.canvas?.addEventListener('mousedown', this._mouseDownEventListener);
        scanController.canvas?.addEventListener('mouseup', this._mouseUpEventListener);
        scanController.canvas?.addEventListener('mousemove', this._mouseMoveEventListener);

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Remove event listeners
        scanController.canvas?.removeEventListener('mousedown', this._mouseDownEventListener);
        scanController.canvas?.removeEventListener('mouseup', this._mouseUpEventListener);
        scanController.canvas?.removeEventListener('mousemove', this._mouseMoveEventListener);

        // Re-enables camera controls
        scanController.camera.setRotationEnabled(true);
        scanController.camera.setPanEnabled(true);

        super.deactivate();
    }

    private onMouseDownEvent(event: MouseEvent): void {
        if (event.button === 0) {
            // Left mouse only
            this._alignSoleTool.mouseDownLogic(event.offsetX, event.offsetY);
            if (this._alignSoleTool.isInTransform) {
                // Disable camera controls during transformation
                ScanController.instance?.camera.setRotationEnabled(false);
                ScanController.instance?.camera.setPanEnabled(false);
                event.preventDefault();
            }
        }
    }

    private onMouseUpEvent(event: MouseEvent): void {
        if (event.button === 0) {
            // Left mouse only
            this._alignSoleTool.mouseUpLogic();
            // Re-enables camera controls
            ScanController.instance?.camera.setRotationEnabled(true);
            ScanController.instance?.camera.setPanEnabled(true);
        }
    }

    private onMouseMoveEvent(event: MouseEvent): void {
        this._alignSoleTool.mouseMoveLogic(event.offsetX, event.offsetY);
    }
}

export default AlignSoleMode;
