import NavigationController from '@/3d-app/navigation/NavigationController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import { Vector3, PerspectiveCamera, Object3D, Box3 } from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { degToRad } from 'three/src/math/MathUtils';
import type ICameraControls from './controls/ICameraControls';
import type { TCameraParams } from './TCameraParams';

abstract class AnimatedCamera extends PerspectiveCamera {
    protected _cameraControls: ICameraControls;

    private _currentTweenTarget: TWEEN.Tween<any> | undefined;
    private _currentTweenSpherical: TWEEN.Tween<any> | undefined;
    private _isOrthographic: boolean;
    private _originalFov: number;

    public get controls(): ICameraControls {
        return this._cameraControls;
    }

    public setRotationEnabled(enabled: boolean, updateNavController: boolean = false) {
        this._cameraControls.enableRotate = enabled;
        if (updateNavController && NavigationController.isInitialized) {
            NavigationController.setNavigationLocked(!enabled);
        }
    }

    public setPanEnabled(enabled: boolean) {
        this._cameraControls.enablePan = enabled;
    }

    public getIsOrthographic(): boolean {
        return this._isOrthographic;
    }

    /**
     * Create a perspective camera with an attached OrbitControls
     * @param cameraParams
     *      aspect: enum,
     *      near: enum,
     *      far: enum,
     *      fov: enum,
     *      name: string,
     *      rendererDomElement?: HTMLCanvasElement, #renderer.domElement
     *      target: Vector3
     */
    constructor(cameraParams: TCameraParams) {
        super(
            cameraParams.fov || 75,
            cameraParams.aspect || cameraParams.rendererDomElement.width / cameraParams.rendererDomElement.height,
            cameraParams.near || 0.01,
            cameraParams.far || 1000,
        );

        if (cameraParams.name) {
            this.name = cameraParams.name;
        }

        this._originalFov = cameraParams.fov || 75;
        this._isOrthographic = false;
        this._cameraControls = this.createControls(cameraParams.rendererDomElement); // Deferred initialization of controls
    }

    protected abstract createControls(rendererDomElement: HTMLCanvasElement): ICameraControls;

    protected initializeControls(): void {
        this._cameraControls.addEventListener('change', () => {
            if (NavigationController.isInitialized) {
                NavigationController.updateCameraRotation(this.quaternion); // Update navigation cube
            }
            if (this._currentTweenSpherical?.isPlaying() || this._currentTweenTarget?.isPlaying()) {
                // Stop current animation if there is
                this._currentTweenSpherical?.stop();
                this._currentTweenTarget?.stop();
                // Force quaternion after lookAt
                const savedQuaternion = this.quaternion.clone();
                this._cameraControls.update();
                this.quaternion.copy(savedQuaternion);
            }
        });
    }

    /**
     * Set the camera mode to orthographic or perspective
     * Note: This is a fake orthographic, in reality the camera fov is set to a small value, and put far away.
     * @param isOrthographic true for orthographic view, false for perspective
     */
    public setOrthographic(isOrthographic: boolean): void {
        this._isOrthographic = isOrthographic;

        const width = 2 * Math.tan((this.fov * Math.PI) / 360);
        const radius = this.getRadius();

        // Adapt the fov
        if (this._isOrthographic) {
            this._originalFov = this.fov;
            this.fov = 1;
        } else {
            this.fov = this._originalFov;
        }
        this.updateProjectionMatrix();
        // Compute the position difference to compensate the fov adjustment
        this.position.sub(this.controls.target);
        const ratio = 2 * Math.tan(degToRad(this.fov / 2));
        const distance = (width * radius) / ratio;
        // Adapt min and max distance
        this.controls.minDistance = (this.controls.minDistance * width) / ratio;
        this.controls.maxDistance = (this.controls.maxDistance * width) / ratio;
        // Offset the position
        this.position.normalize().multiplyScalar(distance);
        this.position.add(this.controls.target);
    }

    /**
     * Set camera rotation (and position) from orbit direction
     * @param direction direction vector (normalized)
     * @returns a promise resolved when the transition is finished
     */
    setCameraOrbit(direction: Vector3): Promise<void> {
        if (!this.controls.enableRotate) {
            return Promise.resolve();
        }

        const { phi, theta } = MeshUtils.sphericalFromCartesian(direction.x, direction.y, direction.z);

        return this.setSphericalCoordinates({
            theta,
            phi,
            animate: true,
        });
    }

    /**
     * Animate camera to look at selection, and fit selection
     * @param selection the array of objects to fit within the camera view.
     * @param offset the offset factor to control the spacing around the object
     * @param animate wether the transition should be animated of not (false by default)
     * @param duration duration of the transition animation if there is, in ms (400 by default)
     * @returns a promise resolved when the transition is finished
     */
    targetSelection(
        selection: Array<Object3D>,
        offset: number = 0,
        animate: boolean = false,
        duration: number = 400,
    ): Promise<void> {
        const box = new Box3();
        for (let i = 0; i < selection.length; i += 1) {
            box.expandByObject(selection[i]);
        }
        const target = new Vector3();
        box.getCenter(target);

        const finalPosition = MeshUtils.fitCameraToSelection(this, target, selection, offset);
        const convPosition = MeshUtils.sphericalFromCartesian(finalPosition.x, finalPosition.y, finalPosition.z);

        return new Promise((resolve) => {
            Promise.all([
                this.setSphericalCoordinates({
                    theta: convPosition.theta,
                    phi: convPosition.phi,
                    radius: convPosition.radius,
                    animate,
                    duration,
                }),
                this.setTargetKeepingOrbit(target, animate, duration),
            ]).then(() => {
                resolve();
            });
        });
    }

    /**
     * Reset the camera position, rotation and zoom
     * @param zOffset the offset in Z of the camera
     * @param targetPosition new position of the target (default is world origin)
     * @param animate wether the transition should be animated of not (true by default)
     * @param duration duration of the transition animation if there is, in ms (1000 by default)
     * @returns a promise resolved when the transition is finished
     */
    homeCamera(
        zOffset: number = 10,
        targetPosition: Vector3 = new Vector3(),
        animate: boolean = true,
        duration: number = 1000,
    ): Promise<void> {
        return new Promise((resolve) => {
            Promise.all([
                this.setSphericalCoordinates({
                    theta: 0,
                    phi: Math.PI / 2,
                    radius: zOffset * (75 / this.fov),
                    animate,
                    duration,
                }),
                this.setTargetKeepingOrbit(targetPosition, animate, duration),
            ]).then(() => {
                resolve();
            });
        });
    }

    /**
     * Set camera coordinates from spherical. The transition can be animated.
     * @param params
     *      theta: target theta coord (current one by default),
     *      phi: target phi coord (current one by default),
     *      radius: target radius coord (current one by default),
     *      animate: wether the transition should be animated of not (false by default),
     *      duration: duration of the transition animation if there is, in ms (400 by default),
     * @returns a promise resolved when the transition animation is done
     */
    setSphericalCoordinates(params: {
        theta?: number;
        phi?: number;
        radius?: number;
        animate?: boolean;
        duration?: number;
    }): Promise<void> {
        return new Promise((resolve) => {
            this._currentTweenSpherical?.stop();

            const needRotation = params.theta !== undefined || params.phi !== undefined;

            const theta = params.theta ?? this.getTheta();
            const phi = params.phi ?? this.getPhi();
            const radius = params.radius || this.getRadius();
            const animate = params.animate || false;
            const duration = params.duration || 400;

            const targetPosition = MeshUtils.cartesianFromSpherical(radius, phi, theta);

            const currentRotation = this.quaternion.clone(); // Save initial rotation
            const currentPosition = this.position.clone(); // Save initial position
            this.position.copy(targetPosition).add(this.controls.target);
            if (needRotation) {
                this.lookAt(this.controls.target);
            }
            const targetRotation = this.quaternion.clone();
            this.position.copy(currentPosition); // Restore initial position
            this.quaternion.copy(currentRotation); // Restore initial rotation

            const ending = () => {
                this.position.copy(targetPosition).add(this.controls.target);
                this.quaternion.copy(targetRotation);
                if (NavigationController.isInitialized) {
                    NavigationController.updateCameraRotation(this.quaternion); // Update navigation cube
                }
                resolve();
            };
            if (animate) {
                const anim = {
                    t: 0,
                    radius: this.getRadius(),
                };
                this._currentTweenSpherical = new TWEEN.Tween(anim)
                    .to({ t: 1, radius }, duration)
                    .onUpdate(() => {
                        this.quaternion.slerp(targetRotation, anim.t);
                        if (NavigationController.isInitialized) {
                            NavigationController.updateCameraRotation(this.quaternion); // Update navigation cube
                        }

                        this.position
                            .copy(new Vector3(0, 0, anim.radius).applyQuaternion(this.quaternion))
                            .add(this.controls.target);
                    })
                    .onComplete(ending)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start();
            } else {
                ending();
            }
        });
    }

    /**
     * Change target position. The transition can be animated.
     * Position and orientation of the camera will be adapted to keep the same relative to the new target
     * @param target new target position
     * @param animate wether the transition should be animated of not (false by default)
     * @param duration duration of the transition animation if there is, in ms (400 by default)
     * @returns a promise resolved when the transition animation is done
     */
    setTargetKeepingOrbit(target: THREE.Vector3, animate: boolean = false, duration: number = 400): Promise<void> {
        return new Promise((resolve) => {
            this._currentTweenTarget?.stop();
            const ending = () => {
                this.position.sub(this.controls.target);
                this.controls.target = target;
                this.position.add(this.controls.target);
                resolve();
            };
            if (animate) {
                const initTarget = this.controls.target as Vector3;
                const anim = { t: 0 };
                this._currentTweenTarget = new TWEEN.Tween(anim)
                    .to({ t: 1 }, duration)
                    .onUpdate(() => {
                        this.position.sub(this.controls.target);
                        this.controls.target.copy(initTarget.lerp(target, anim.t));
                        this.position.add(this.controls.target);
                    })
                    .onComplete(ending)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start();
            } else {
                ending();
            }
        });
    }

    /**
     * Change target position. The transition can be animated.
     * Position of the camera will not change, but the orientation will be adapted to focus new target.
     * @param target new target position
     * @param animate wether the transition should be animated of not (false by default)
     * @param duration duration of the transition animation if there is, in ms (400 by default)
     * @returns a promise resolved when the transition animation is done
     */
    setTarget(target: THREE.Vector3, animate: boolean = false, duration: number = 400): Promise<void> {
        return new Promise((resolve) => {
            this._currentTweenSpherical?.stop();
            this._currentTweenTarget?.stop();

            const currentRotation = this.quaternion.clone(); // Save initial rotation
            this.lookAt(target);
            const targetRotation = this.quaternion.clone();
            this.quaternion.copy(currentRotation); // Restore initial rotation

            const ending = () => {
                this.controls.target = target;
                this.quaternion.copy(targetRotation);
                resolve();
            };
            if (animate) {
                const initTarget = this.controls.target as Vector3;
                const anim = { t: 0 };
                this._currentTweenSpherical = new TWEEN.Tween(anim)
                    .to({ t: 1 }, duration)
                    .onUpdate(() => {
                        this.controls.target.copy(initTarget.lerp(target, anim.t));
                        this.quaternion.slerp(targetRotation, anim.t);
                        if (NavigationController.isInitialized) {
                            NavigationController.updateCameraRotation(this.quaternion); // Update navigation cube
                        }
                    })
                    .onComplete(ending)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start();
            } else {
                ending();
            }
        });
    }

    /**
     * Get phi (same as alpha)
     * @returns phi in radians
     */
    getPhi(): number {
        return this.position
            .clone()
            .sub(this.controls.target)
            .angleTo(new Vector3(0, 1, 0));
    }

    /**
     * Set phi (same as alpha)
     * @param {number} phi in radian
     */
    setPhi(phi: number, animate: boolean = false, duration: number = 400): Promise<void> {
        return new Promise((resolve) => {
            this.setSphericalCoordinates({
                phi,
                animate,
                duration,
            }).then(() => {
                resolve();
            });
        });
    }

    /**
     * Get theta (same as beta)
     * @returns theta in radians
     */
    getTheta(): number {
        const direction = this.position.clone().sub(this.controls.target);
        direction.y = 0;
        const angle = direction.angleTo(new Vector3(0, 0, 1));
        if (direction.x < 0) {
            return -angle;
        }
        return angle;
    }

    /**
     * Define theta (same as beta)
     * @param {number} theta in radian 0 = top view, PI/2 = bottom, PI/4 = front view, -PI/4 = back view
     */
    setTheta(theta: number, animate: boolean = false, duration: number = 400): Promise<void> {
        return new Promise((resolve) => {
            this.setSphericalCoordinates({
                theta,
                animate,
                duration,
            }).then(() => {
                resolve();
            });
        });
    }

    /**
     * Get radius (distance from camera to target)
     * @returns radius
     */
    getRadius(): number {
        return this.position.distanceTo(this.controls.target);
    }

    /**
     * Define radius
     * @param {number} radius
     */
    setRadius(radius: number, animate: boolean = false, duration: number = 400): Promise<void> {
        return new Promise((resolve) => {
            this.setSphericalCoordinates({
                radius,
                animate,
                duration,
            }).then(() => {
                resolve();
            });
        });
    }

    onResize(): void {
        this.aspect = window.innerWidth / window.innerHeight;
        this.updateProjectionMatrix();
    }

    // eslint-disable-next-line class-methods-use-this
    render(): void {
        TWEEN.update();
    }
}

export default AnimatedCamera;
