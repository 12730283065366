import RouteNameEnum from '@/router/routeNameEnum';
import useAuthStore from '@/domains/authentication/store/auth.store';
import i18n from '@/translations';
import RoleEnum from '@/domains/authentication/typescript/RoleEnum';
import ForgottenPasswordView from '@/views/ForgottenPasswordView.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';
import InvitationView from '@/views/InvitationView.vue';
import { createRouter, createWebHistory } from 'vue-router';

const { t } = i18n.global;

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/patients',
            children: [
                {
                    path: '',
                    name: RouteNameEnum.Patients,
                    component: () => import('@/views/PatientsView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
                {
                    path: 'new-patient',
                    name: RouteNameEnum.NewPatient,
                    component: () => import('@/domains/patient/views/PatientEditView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
                {
                    path: 'edit-patient/:id',
                    name: RouteNameEnum.EditPatient,
                    component: () => import('@/domains/patient/views/PatientEditView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
            ],
        },
        {
            path: '/orders',
            children: [
                {
                    path: '',
                    name: RouteNameEnum.Orders,
                    component: () => import('@/views/OrdersView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
                {
                    path: 'patient/:id',
                    name: RouteNameEnum.PatientOrders,
                    component: () => import('@/views/OrdersView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
                {
                    path: 'new-order',
                    name: RouteNameEnum.NewOrder,
                    component: () => import('@/domains/order/views/OrderEditView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
                {
                    path: 'edit-order/:id',
                    name: RouteNameEnum.EditOrder,
                    component: () => import('@/domains/order/views/OrderEditView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_DOCTOR],
                    },
                },
            ],
        },
        {
            path: '/settings',
            name: RouteNameEnum.Settings,
            component: () => import('@/views/SettingsView.vue'),
            meta: {
                requiresAuth: true,
                roles: [RoleEnum.ROLE_DOCTOR],
            },
        },
        {
            path: '/order/:id/report',
            name: RouteNameEnum.OrderReport,
            component: () => import('@/views/OrderReportView.vue'),
            meta: {
                requiresAuth: true,
                roles: [RoleEnum.ROLE_DOCTOR],
            },
        },
        {
            path: '/order/:id/scan',
            name: RouteNameEnum.Scan,
            component: () => import('@/views/ScanView.vue'),
            meta: {
                requiresAuth: true,
                roles: [RoleEnum.ROLE_DOCTOR],
            },
        },
        {
            path: '/settings',
            name: RouteNameEnum.Settings,
            component: () => import('@/views/SettingsView.vue'),
            meta: {
                requiresAuth: true,
                roles: [RoleEnum.ROLE_DOCTOR],
            },
        },
        {
            path: '/select-offices',
            name: RouteNameEnum.SelectOffices,
            component: () => import('@/domains/authentication/views/SelectOfficeView.vue'),
            meta: {
                requiresAuth: true,
                roles: [RoleEnum.ROLE_DOCTOR],
            },
        },
        {
            path: '/admin',
            children: [
                {
                    path: 'office',
                    children: [
                        {
                            path: '',
                            name: RouteNameEnum.AdminOffice,
                            component: () => import('@/domains/admin/views/AdminOfficeView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'add',
                            name: RouteNameEnum.AdminOfficeAdd,
                            component: () => import('@/domains/admin/views/AdminOfficeAddView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'edit-office/:id',
                            name: RouteNameEnum.AdminOfficeEdit,
                            component: () => import('@/domains/admin/views/AdminOfficeAddView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                    ],
                },
                {
                    path: 'user',
                    children: [
                        {
                            path: '',
                            name: RouteNameEnum.AdminUser,
                            component: () => import('@/domains/admin/views/AdminUserView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'add',
                            name: RouteNameEnum.AdminUserAdd,
                            component: () => import('@/domains/office/views/OfficeInviteView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'edit-user/:id',
                            name: RouteNameEnum.AdminUserEdit,
                            component: () => import('@/domains/admin/views/AdminUserEditView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'user-secret/:id',
                            name: RouteNameEnum.AdminUserSecret,
                            component: () => import('@/domains/admin/views/AdminUserSecretView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'add-admin',
                            name: RouteNameEnum.AdminAdd,
                            component: () => import('@/domains/admin/views/AdminUserAdminAddView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                    ],
                },
                {
                    path: 'announcement',
                    children: [
                        {
                            path: '',
                            name: RouteNameEnum.AdminAnnouncement,
                            component: () => import('@/domains/admin/views/AdminAnnouncementView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'add',
                            name: RouteNameEnum.AdminAnnouncementAdd,
                            component: () => import('@/domains/admin/views/AdminAnnouncementAddView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                        {
                            path: 'edit-announcement/:id',
                            name: RouteNameEnum.AdminAnnouncementEdit,
                            component: () => import('@/domains/admin/views/AdminAnnouncementAddView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMIN_ERGO],
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/my-office',
            children: [
                {
                    path: 'users',
                    children: [
                        {
                            path: '',
                            name: RouteNameEnum.MyOfficeUser,
                            component: () => import('@/views/OfficeUsersView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMINISTRATOR_OFFICE],
                            },
                        },
                        {
                            path: 'edit/:id',
                            name: RouteNameEnum.OfficeUserEdit,
                            component: () => import('@/domains/office/views/OfficeUserEditView.vue'),
                            meta: {
                                requiresAuth: true,
                                roles: [RoleEnum.ROLE_ADMINISTRATOR_OFFICE],
                            },
                        },
                    ],
                },
                {
                    path: 'invite',
                    name: RouteNameEnum.MyOfficeInvite,
                    component: () => import('@/domains/office/views/OfficeInviteView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_ADMINISTRATOR_OFFICE],
                    },
                },
                {
                    path: 'edit',
                    name: RouteNameEnum.MyOfficeEdit,
                    component: () => import('@/domains/office/views/OfficeEditView.vue'),
                    meta: {
                        requiresAuth: true,
                        roles: [RoleEnum.ROLE_ADMINISTRATOR_OFFICE],
                    },
                },
            ],
        },
        {
            path: '/forgotten-password',
            name: RouteNameEnum.ForgottenPassword,
            component: ForgottenPasswordView,
        },
        {
            path: '/reset-password/:token',
            name: RouteNameEnum.ResetPassword,
            component: ResetPasswordView,
        },
        // INVITATION
        {
            path: '/invite/:token',
            name: RouteNameEnum.Invite,
            component: InvitationView,
        },
        // HOME
        {
            path: '/:token([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?',
            name: RouteNameEnum.Login,
            component: () => import('@/views/LoginView.vue'),
        },
        // FALLBACK
        {
            path: '/:pathMatch(.*)*',
            redirect: '/',
        },
    ],
});

router.afterEach((to) => {
    let pageTitle = 'Podofit';
    if (to.name) {
        pageTitle = t(`page_title.${to.name.toString()}`);
    }
    document.title = `${pageTitle} - Podofit Web`;
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.isUserLoggedIn;
    const { isUserAuthorized } = authStore;
    // UNAUTHENTICATED
    if (to.meta.requiresAuth && !isAuthenticated) {
        return next({ name: RouteNameEnum.Login });
    }

    if (to.name === RouteNameEnum.Login && isAuthenticated) {
        return next({ path: RouteNameEnum.Patients });
    }
    // Check if the user is accessing a route directly or from an external link
    if (!from.name && !isUserAuthorized(to.meta.roles as any[])) {
        // User accessed the route directly or from an external link and is not authorized
        if (authStore.isGranted(RoleEnum.ROLE_ADMIN_ERGO)) {
            return next({ path: RouteNameEnum.AdminOffice });
        }
        return next({ path: RouteNameEnum.Patients });
    }

    // UNAUTHORIZED
    if ((to.meta.roles as any[])?.length && !isUserAuthorized(to.meta.roles as any[])) {
        return false;
    }

    return next();
});

export default router;
