import AlignScanTool from './AlignScanTool';
import ScanController from '@/3d-app/scan/ScanController';
import Mode from '@/3d-app/commons/Mode';
import ModeEnum from '@/3d-app/scan/ModeEnum';

/**
 * Mode for adjusting the current scan on the current sole.
 * The user can move the scan with left click, and rotate the scan around it's pivot point
 * with left click.
 * While in this mode, the camera can't be rotated or panned.
 */
class AlignScanMode extends Mode {
    private _alignScanTool: AlignScanTool;

    private _mouseDownEventListener: EventListener;

    private _mouseUpEventListener: EventListener;

    private _mouseMoveEventListener: EventListener;

    private _contextMenuListener: EventListener;

    constructor() {
        super(ModeEnum.ALIGN_SCAN);

        this._alignScanTool = new AlignScanTool();
        this._mouseDownEventListener = (event: Event) => this.onMouseDownEvent(event as MouseEvent);
        this._mouseUpEventListener = (event: Event) => this.onMouseUpEvent(event as MouseEvent);
        this._mouseMoveEventListener = (event: Event) => this.onMouseMoveEvent(event as MouseEvent);
        this._contextMenuListener = (event: Event) => event.preventDefault();
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Add event listeners
        scanController.canvas?.addEventListener('mousedown', this._mouseDownEventListener);
        scanController.canvas?.addEventListener('mouseup', this._mouseUpEventListener);
        scanController.canvas?.addEventListener('mousemove', this._mouseMoveEventListener);
        scanController.canvas?.addEventListener('contextmenu', this._contextMenuListener);

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        // Remove event listeners
        scanController.canvas?.removeEventListener('mousedown', this._mouseDownEventListener);
        scanController.canvas?.removeEventListener('mouseup', this._mouseUpEventListener);
        scanController.canvas?.removeEventListener('mousemove', this._mouseMoveEventListener);
        scanController.canvas?.removeEventListener('contextmenu', this._contextMenuListener);

        // Re-enables camera controls
        scanController.camera.setRotationEnabled(true);
        scanController.camera.setPanEnabled(true);

        super.deactivate();
    }

    private onMouseDownEvent(event: MouseEvent): void {
        this._alignScanTool.mouseDownLogic(event.button, event.offsetX, event.offsetY);
        if (this._alignScanTool.isInTransform) {
            // Disable camera controls during transformation
            ScanController.instance?.camera.setRotationEnabled(false);
            ScanController.instance?.camera.setPanEnabled(false);
            event.preventDefault();
        }
    }

    private onMouseUpEvent(event: MouseEvent): void {
        this._alignScanTool.mouseUpLogic(event.button);
        // Re-enables camera controls
        if (!this._alignScanTool.isInTransform) {
            ScanController.instance?.camera.setRotationEnabled(true);
            ScanController.instance?.camera.setPanEnabled(true);
        }
    }

    private onMouseMoveEvent(event: MouseEvent): void {
        this._alignScanTool.mouseMoveLogic(event.offsetX, event.offsetY);
    }
}

export default AlignScanMode;
