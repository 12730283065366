import NotificationStatus from '@/domains/common/typescript/NotificationStatus';
import useSettingsStore from '@/domains/settings/store/settings.store';
import scanApi from '@/domains/scan/api/scan.api';
import eventBus from '@/domains/common/services/eventBus';
import isPodonexReady from '@/domains/common/services/isPodonexReady';
import { isMessage } from '@/domains/scan/services/typeGuards';
import { defineStore, storeToRefs } from 'pinia';
import { reactive, ref } from 'vue';
import type ScanFilesBlobInterface from '@/domains/scan/typescript/interfaces/ScanFilesBlobInterface';
import type DeviceInterface from '@/domains/scan/typescript/interfaces/DeviceInterface';

export default defineStore('3d', () => {
    const settingsStore = useSettingsStore();

    const { podonexConfiguration } = storeToRefs(settingsStore);

    const isScanInProcess = ref<boolean>(false);
    const podonexScannerData = ref<DeviceInterface>();
    const isOrderLoading = ref<boolean>(false);

    function stopPollingScan() {
        eventBus.emit('stop-polling-scan');
    }

    async function fetchScannerInfo(): Promise<void> {
        if (podonexConfiguration.value === undefined || !podonexConfiguration.value.scannerId) {
            return;
        }
        const isPodonexReadyToUse = await isPodonexReady();

        if (!isPodonexReadyToUse) {
            eventBus.emit('notification-message', {
                title: 'api.podonex_not_ready',
                status: NotificationStatus.warning,
            });
            return;
        }

        try {
            const deviceInfo = await scanApi.getDeviceInfo(podonexConfiguration.value.scannerId);
            if (isMessage(deviceInfo) || deviceInfo === undefined) {
                eventBus.emit('notification-message', {
                    title: 'scan.scanner.scanner_not_available',
                    status: NotificationStatus.error,
                });
                return;
            }
            podonexScannerData.value = deviceInfo;
        } catch {
            eventBus.emit('notification-message', {
                title: 'api.scanner_not_ready',
                status: NotificationStatus.error,
            });
        }
    }

    const scan = reactive<ScanFilesBlobInterface>({
        obj: new Blob(),
        mtl: new Blob(),
        jpg: new Blob(),
    });

    function $reset() {
        console.info('reset 3D store');
        scan.obj = new Blob();
        scan.mtl = new Blob();
        scan.jpg = new Blob();
        podonexScannerData.value = undefined;
        isScanInProcess.value = false;
    }

    return {
        scan,
        fetchScannerInfo,
        podonexScannerData,
        isScanInProcess,
        isOrderLoading,
        stopPollingScan,
        $reset,
    };
});
