import HydraAPI from '@/domains/common/services/hydraApi';
import transformDTOtoSettings from '@/domains/settings/transformers/transformDTOtoSettings';
import transformSettingstoDTO from '@/domains/settings/transformers/transformSettingstoDTO';
import transformDTOtoPodonexConfiguration from '@/domains/settings/transformers/transformDTOtoPodonexConfiguration';
import transformPodonexConfigurationtoDTO from '@/domains/settings/transformers/transformPodonexConfigurationtoDTO';
import type SettingsDTOInterface from '@/domains/settings/typescript/interfaces/SettingsDTOInterface';
import type SettingsInterface from '@/domains/settings/typescript/interfaces/SettingsInterface';
import type PodonexConfigurationInterface from '@/domains/settings/typescript/interfaces/PodonexConfigurationInterface';
import type PodonexConfigurationDTOInterface from '@/domains/settings/typescript/interfaces/PodonexConfigurationDTOInterface';

const getManufactureSettings = async (settingsId: SettingsDTOInterface['id']): Promise<SettingsInterface> => {
    const url = `/manufacture_preferences/${settingsId}`;
    const data = await HydraAPI.getJson<SettingsDTOInterface>(url);

    return transformDTOtoSettings(data);
};

const getManufactureSettingsFromIri = async (url: string): Promise<SettingsInterface> => {
    const data = await HydraAPI.getJson<SettingsDTOInterface>(url);

    return transformDTOtoSettings(data);
};

const createManufactureSettings = async (settingsForm: SettingsInterface): Promise<SettingsInterface> => {
    const settingsDTO = transformSettingstoDTO(settingsForm);
    const url = '/manufacture_preferences';
    // The operation here is to get rid of the id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...newSettings } = settingsDTO;
    const body = JSON.stringify(newSettings);
    const data = await HydraAPI.postJson<SettingsDTOInterface>(url, { body });

    return transformDTOtoSettings(data);
};

const updateManufactureSettings = async (
    settingsForm: SettingsInterface,
    settingsId: SettingsDTOInterface['id'],
): Promise<SettingsInterface> => {
    const settingsDTO = transformSettingstoDTO(settingsForm);
    const url = `/manufacture_preferences/${settingsId}`;
    const body = JSON.stringify(settingsDTO);
    const data = await HydraAPI.putJson<SettingsDTOInterface>(url, { body });

    return transformDTOtoSettings(data);
};

const getPodonexConfiguration = async (
    configurationId: PodonexConfigurationDTOInterface['id'],
): Promise<PodonexConfigurationInterface> => {
    const url = `/podonext_configurations/${configurationId}`;
    const data = await HydraAPI.getJson<PodonexConfigurationDTOInterface>(url);

    return transformDTOtoPodonexConfiguration(data);
};

const getPodonexConfigurationFromIri = async (url: string): Promise<PodonexConfigurationInterface> => {
    const data = await HydraAPI.getJson<PodonexConfigurationDTOInterface>(url);

    return transformDTOtoPodonexConfiguration(data);
};

const createPodonexConfiguration = async (
    podonexForm: PodonexConfigurationInterface,
): Promise<PodonexConfigurationInterface> => {
    const configurationDTO = transformPodonexConfigurationtoDTO(podonexForm);
    const url = '/podonext_configurations';
    // The operation here is to get rid of the id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...newConfiguration } = configurationDTO;
    const body = JSON.stringify(newConfiguration);
    const data = await HydraAPI.postJson<PodonexConfigurationDTOInterface>(url, { body });

    return transformDTOtoPodonexConfiguration(data);
};

const updatePodonexConfiguration = async (
    podonexForm: PodonexConfigurationInterface,
    podonexId: PodonexConfigurationDTOInterface['id'],
): Promise<PodonexConfigurationInterface> => {
    const configurationDTO = transformPodonexConfigurationtoDTO(podonexForm);
    const url = `/podonext_configurations/${podonexId}`;
    const body = JSON.stringify(configurationDTO);
    const data = await HydraAPI.putJson<PodonexConfigurationDTOInterface>(url, { body });

    return transformDTOtoPodonexConfiguration(data);
};

export default {
    getManufactureSettings,
    getManufactureSettingsFromIri,
    createManufactureSettings,
    updateManufactureSettings,

    getPodonexConfigurationFromIri,
    getPodonexConfiguration,
    createPodonexConfiguration,
    updatePodonexConfiguration,
};
