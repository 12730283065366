import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type AdminUserInterface from '@/domains/admin/typescript/AdminUserInterface';

export default defineStore('adminUser', () => {
    const users = ref<AdminUserInterface[]>();
    const selectedUser = ref<AdminUserInterface>();
    const totalUsers = ref(0);

    const hasUserSelected = computed<boolean>(() => selectedUser.value !== undefined);

    function selectUserById(userId: string): void {
        selectedUser.value = users.value?.find((user) => user.id === userId);
    }

    function isUserSelected(user: AdminUserInterface): boolean {
        return selectedUser.value?.id === user.id;
    }

    function unselectUser(): void {
        selectedUser.value = undefined;
    }

    function getUserById(id: string): AdminUserInterface | undefined {
        return users.value?.find((user) => user.id === id);
    }

    function $reset() {
        users.value = undefined;
        selectedUser.value = undefined;
    }

    return {
        users,
        selectedUser,
        hasUserSelected,
        totalUsers,
        selectUserById,
        isUserSelected,
        unselectUser,
        getUserById,

        $reset,
    };
});
