enum RouteNameEnum {
    'Patients' = 'patients',
    'NewPatient' = 'new_patient',
    'EditPatient' = 'edit_patient',
    'Orders' = 'orders',
    'NewOrder' = 'new_order',
    'EditOrder' = 'edit_order',
    'OrderReport' = 'order_report',
    'Settings' = 'settings',
    'Scan' = 'scan',
    'Login' = 'login',
    'SelectOffices' = 'select_office',
    'AdminOffice' = 'admin_office',
    'AdminOfficeAdd' = 'admin_office_add',
    'AdminOfficeEdit' = 'admin_office_edit',
    'AdminUser' = 'admin_user',
    'AdminUserAdd' = 'admin_user_add',
    'AdminUserEdit' = 'admin_user_edit',
    'AdminAnnouncement' = 'admin_announcement',
    'AdminAnnouncementAdd' = 'admin_announcement_add',
    'AdminAnnouncementEdit' = 'admin_announcement_edit',
    'AdminUserSecret' = 'admin_user_secret',
    'ForgottenPassword' = 'forgotten_password',
    'ResetPassword' = 'reset_password',
    'MyOfficeUser' = 'my_office_user',
    'MyOfficeEdit' = 'my_office_edit',
    'Invite' = 'invite',
    'OfficeUserEdit' = 'office_user_edit',
    'MyOfficeInvite' = 'my_office_invite',
    'Register' = 'register',
    'PatientOrders' = 'patient_orders',
    'AdminAdd' = 'admin_add',
}
export default RouteNameEnum;
