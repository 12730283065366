<template>
    <img
        class="absolute bottom-0 left-0 -z-0 w-1/5"
        src="@/assets/images/background-lines-left-brown.svg"
        aria-hidden="true"
    />
    <img
        class="absolute bottom-0 right-0 -z-0 w-1/4"
        src="@/assets/images/background-lines-right-brown.svg"
        aria-hidden="true"
    />
    <slot />
</template>
