import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import { getRhinoLoader } from '@/3d-app/commons/RhinoUtils';
import { Box3, BoxGeometry, Color, Group, Line, Matrix4, Mesh, Vector2, Vector3 } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

class TemplateMesh {
    private static _templatePatterns = [
        'Homme_Bout_Carre_',
        'Homme_Bout_Standard_',
        'Femme_Bout_Carre_',
        'Femme_Bout_Standard_',
        'Femme_Bout_Escarpin_',
        'Enfant_Bout_Standard_',
    ];

    private static _cuttingLengths = ['Full_', '34_', '12_'];

    private _templateObject: Group;

    private _templateGeometry: Line2;

    private _raycastMesh: Mesh;

    private _material: LineMaterial;

    private _boundingBox: Box3;

    private _isReady: boolean;

    private _isVisible: boolean;

    /**
     * Get the whole template
     */
    get mesh(): Group {
        return this._templateObject;
    }

    /**
     * Get the mesh used for raycast
     */
    get raycastMesh(): Mesh {
        return this._raycastMesh;
    }

    /**
     * Get the template ready status (ready is fully loaded)
     */
    get isReady(): boolean {
        return this._isReady;
    }

    /**
     * Get only the template geometry, without the attached childs
     */
    get templateGeometry(): Line2 {
        return this._templateGeometry;
    }

    constructor() {
        this._templateGeometry = new Line2();
        this._templateObject = new Group();
        this._boundingBox = new Box3();
        this._raycastMesh = new Mesh();
        this._isVisible = true;
        this._isReady = false;
        // Create line material
        const renderSize = new Vector2();
        ScanController.instance?.renderer.getSize(renderSize);
        this._material = new LineMaterial({
            dashed: false,
            resolution: renderSize, // Need to be adapted on resize
            transparent: true,
        });
        this.setGrayedOut(false);
    }

    get boundingBox(): Box3 {
        return this._boundingBox;
    }

    get origin(): Vector3 {
        return this._templateGeometry.position;
    }

    /**
     * Load the template mesh from 3dm ArrayBuffer or from default asset
     * @returns promise resolved when object is loaded, with the loaded group
     */
    loadTemplate(isRightFoot: boolean): Promise<Group> {
        const loader = getRhinoLoader();

        const templatePath = TemplateMesh.generateTemplatePath(isRightFoot);

        // Load the 3DM file
        return new Promise((resolve, reject) => {
            loader.load(
                templatePath,
                (object) => {
                    const lineGeometry = new LineGeometry();
                    lineGeometry.fromLine(object.children[0] as Line);

                    this._templateGeometry = new Line2(lineGeometry, this._material);

                    this.adaptMesh(isRightFoot);

                    // Gray it out if it's not the current one
                    if (ScanController.instance?.isRightFoot !== isRightFoot) {
                        this.setGrayedOut(true);
                    }

                    this.updateVisibility(); // Update visibility

                    // Set template status to ready
                    this._isReady = true;

                    resolve(this._templateObject);
                },
                undefined,
                (error) => reject(error),
            );
        });
    }

    static generateTemplatePath(isRightFoot: boolean): string {
        return `/models/templates/${TemplateMesh._templatePatterns[ScanController.getPattern()]}${TemplateMesh._cuttingLengths[ScanController.getCuttingLength()]}${isRightFoot ? 'Right' : 'Left'}.3dm`;
    }

    private adaptMesh(isRightFoot: boolean, readapt: boolean = false) {
        if (readapt) {
            this._templateObject.position.x = 0;
            this._templateObject.updateMatrixWorld(true);
            this._templateGeometry.position.set(0, 0, 0);
        }

        this._templateGeometry.scale.setScalar(ScanController.SCALE_TO_M_FACTOR * ScanController.footSize); // Resize the template to the correct foot size

        const bbox = new Box3().setFromObject(this._templateGeometry); // Get bounding box
        bbox.expandByPoint(this._templateGeometry.position);

        const posDiff = bbox.min.add(bbox.max).divideScalar(-2); // Find object center with bounding box info
        posDiff.multiply(new Vector3(1, 1, 0)); // Set position to ground

        // Update object center
        this._templateGeometry.position.copy(posDiff);

        if (!readapt) {
            // Update object orientation
            this._templateGeometry.applyMatrix4(new Matrix4().makeRotationX(Math.PI));

            this._templateObject.add(this._templateGeometry);
            this._raycastMesh = new Mesh(new BoxGeometry());
        }

        this._boundingBox.setFromObject(this._templateObject); // Get final bounding box

        // Offset the position for right or left
        this._templateObject.position.x = ScanController.getFootOffset(isRightFoot);

        // Create and position raycast mesh
        const bbSize = new Vector3();
        this._boundingBox.getSize(bbSize);
        this._raycastMesh.scale.set(bbSize.x, bbSize.y, bbSize.z);
        this._raycastMesh.position.copy(this._templateObject.position);
        this._raycastMesh.updateMatrixWorld(); // Force matrix update (cause mesh not in scene)
        this._templateGeometry.renderOrder = 50;
    }

    /**
     * Readapt the template after the footSize is updated
     */
    readaptSize(isRightFoot: boolean): void {
        this.adaptMesh(isRightFoot, true);
    }

    setGrayedOut(isGrayedOut: boolean): void {
        if (isGrayedOut) {
            this._material.color = new Color('#999999');
            this._material.linewidth = 3;
        } else {
            this._material.color = new Color('#11ff11');
            this._material.linewidth = 5;
        }
    }

    setIsVisible(isVisible: boolean): void {
        this._isVisible = isVisible;
        this.updateVisibility();
    }

    getIsVisible(): boolean {
        return this._isReady && this._isVisible;
    }

    updateVisibility(): void {
        let isCurrentOrVisible = true;
        if (ScanController.instance) {
            isCurrentOrVisible =
                ScanController.instance.isOtherFootVisible || ScanController.instance.currentTemplateMesh === this;
        }
        this._templateGeometry.visible = this._isVisible && isCurrentOrVisible;
    }

    /**
     * Update materials that need renderer size (lines)
     * @param newRenderSize new size of the renderer
     */
    resize(newRenderSize: Vector2): void {
        this._material.resolution = newRenderSize;
    }

    dispose(): void {
        MeshUtils.disposeObject(this._templateObject);
        this._templateObject = new Group();
    }
}

export default TemplateMesh;
