<script setup lang="ts">
import RouteNameEnum from '@/router/routeNameEnum';
import TheHeader from '@/domains/common/components/TheHeader.vue';
import TheNotification from '@/domains/common/components/TheNotification.vue';
import TheLineBackground from '@/domains/common/components/TheLineBackground.vue';
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import '@vuepic/vue-datepicker/dist/main.css';

const route = useRoute();

const AUTH_LAYOUT_ROUTES = [
    RouteNameEnum.Login,
    RouteNameEnum.ForgottenPassword,
    RouteNameEnum.ResetPassword,
    RouteNameEnum.Invite,
];

const containerClass = computed(() => {
    if (!AUTH_LAYOUT_ROUTES.includes(route.name?.toString() as RouteNameEnum)) {
        return 'pb-3 h-screen flex flex-col overflow-hidden';
    }

    return '';
});

const isHeaderVisible = computed(() => {
    return !AUTH_LAYOUT_ROUTES.includes(route.name?.toString() as RouteNameEnum) && route.name;
});

const shouldDisplayLineBackground = computed(() => {
    return ![AUTH_LAYOUT_ROUTES, RouteNameEnum.Scan].includes(route.name?.toString() as RouteNameEnum);
});
</script>

<template>
    <div :class="[containerClass, 'h-screen']">
        <TheHeader v-if="isHeaderVisible" />
        <TheNotification />
        <TheLineBackground v-if="shouldDisplayLineBackground">
            <RouterView />
        </TheLineBackground>

        <RouterView v-else />
    </div>
</template>
