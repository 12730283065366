import AlignPointTool from './AlignPointTool';
import eventBus from '@/3d-app/commons/EventBus';
import Mode from '@/3d-app/commons/Mode';
import OrbitDirection from '@/3d-app/navigation/OrbitDirections';
import ScanController from '@/3d-app/scan/ScanController';
import ModeEnum from '@/3d-app/scan/ModeEnum';
import { Plane, type Mesh } from 'three';

/**
 * Mode for placing the four points on current scan, and aligning the scan on
 * the current sole accordingly to those points.
 * Place point with left click.
 * When the fourth point is placed, the transformation is launched.
 * The alignment points will stay on the scan.
 */

class AlignPointMode extends Mode {
    private _alignPointTool: AlignPointTool;

    private _clickEventListener: EventListener;

    private _footUpdateEventListener: symbol;

    constructor() {
        super(ModeEnum.ALIGN_POINTS);

        this._alignPointTool = new AlignPointTool();
        this._clickEventListener = (event: Event) => this.onClickEvent(event as MouseEvent);
        this._footUpdateEventListener = Symbol('Foot update event listener');
    }

    loadPoints(points: Array<Mesh>, isRightPoints: boolean): void {
        this._alignPointTool.loadPoints(points, isRightPoints);
    }

    /**
     * Toggle points visibility on or off
     * @param isVisible true for visible points, false for invisible
     */
    setPointsVisibility(isVisible: boolean) {
        this._alignPointTool.setPointsVisibility(isVisible);
    }

    /**
     * Force recomputing the scan alignment and position
     * @param isRightScan true to update right scan, false for left one
     */
    recomputeScanPosition(isRightScan: boolean): void {
        this._alignPointTool.computeTransformations(isRightScan);
    }

    /**
     * Compute distance between the arch point and the alignment plane, on specified scan
     * @param {boolean} isRightScan true for right arch distance, false for left
     * @returns the distance, or 0 it the arch point does not exist
     */
    getScanArchHeight(isRightScan: boolean): number {
        const points = this._alignPointTool.getScanPoints(isRightScan);
        if (points.length !== 4) {
            return 0;
        }
        const plane = new Plane().setFromCoplanarPoints(points[0].position, points[1].position, points[2].position);
        return Math.abs(plane.distanceToPoint(points[3].position)) / ScanController.SCALE_TO_M_FACTOR;
    }

    activate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        scanController.camera.setCameraOrbit(OrbitDirection.to('Top'));
        scanController.camera.setRotationEnabled(false, true); // Lock camera rotation
        scanController.canvas?.addEventListener('click', this._clickEventListener);
        this._footUpdateEventListener = eventBus.on('selected-foot-updated', ScanController.focusCurrentFoot);
        AlignPointTool.addCustomCursor();
        this._alignPointTool.updateCustomCursor();

        super.activate();
    }

    deactivate(): void {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        scanController.camera.setRotationEnabled(true, true);
        scanController.canvas?.removeEventListener('click', this._clickEventListener);
        eventBus.off(this._footUpdateEventListener);
        AlignPointTool.removeCustomCursor();
        super.deactivate();
    }

    private onClickEvent(event: MouseEvent): void {
        this._alignPointTool.mouseClickLogic(event.offsetX, event.offsetY);
    }

    public dispose(all: boolean = true): void {
        this._alignPointTool.dispose(all);
    }
}

export default AlignPointMode;
