<script setup lang="ts">
import useAuthStore from '@/domains/authentication/store/auth.store';
import UserHeader from '@/domains/common/components/UserHeader.vue';
import AdminHeader from '@/domains/common/components/AdminHeader.vue';
import RoleEnum from '@/domains/authentication/typescript/RoleEnum';
import { computed } from 'vue';

const authStore = useAuthStore();

const isAdminErgo = computed(() => authStore.isGranted(RoleEnum.ROLE_ADMIN_ERGO));
</script>

<template>
    <AdminHeader v-if="isAdminErgo" />
    <UserHeader v-else />
</template>
