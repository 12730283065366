import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type AdminOfficeInterface from '@/domains/admin/typescript/AdminOfficeInterface';

export default defineStore('adminOffice', () => {
    const offices = ref<AdminOfficeInterface[]>();
    const selectedOffice = ref<AdminOfficeInterface>();

    const totalOffices = computed(() => offices.value?.length ?? 0);
    const hasOfficeSelected = computed<boolean>(() => selectedOffice.value !== undefined);

    function selectOfficeById(officeId: string): void {
        selectedOffice.value = offices.value?.find((office) => office.id === officeId);
    }

    function isOfficeSelected(office: AdminOfficeInterface): boolean {
        return selectedOffice.value?.id === office.id;
    }

    const unselectOffice = (): void => {
        selectedOffice.value = undefined;
    };

    function getOfficeById(id: string): AdminOfficeInterface | undefined {
        return offices.value?.find((office) => office.id === id);
    }

    function $reset() {
        offices.value = undefined;
        selectedOffice.value = undefined;
    }

    return {
        offices,
        selectedOffice,
        hasOfficeSelected,
        totalOffices,

        selectOfficeById,
        isOfficeSelected,
        unselectOffice,
        getOfficeById,

        $reset,
    };
});
