import PositionGizmo from './gizmos/PositionGizmo';
import Gizmo from './gizmos/Gizmo';
import RotationGizmo from './gizmos/RotationGizmo';
import ScaleGizmo from './gizmos/ScaleGizmo';
import ScanController from '@/3d-app/scan/ScanController';
import Tool from '@/3d-app/commons/Tool';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import { get3dHasChanged, set3dHasChanged } from '@/3d-vue-api/storeAPI';
import { Group, Matrix4, Quaternion, Raycaster, Vector2, Vector3 } from 'three';
import type ElementMesh from './meshes/ElementMesh';

class TransformTool extends Tool {
    private _origin: Group;

    private _raycaster: Raycaster;

    private _attachedMesh: ElementMesh | null;
    get attachedMesh(): ElementMesh | null {
        return this._attachedMesh;
    }

    private _gizmos: Array<Gizmo>;
    private _scaleGizmos: Array<ScaleGizmo>;

    private _pickedGizmo: Gizmo | null = null;
    get isInTransform(): boolean {
        return this._pickedGizmo !== null;
    }

    private _highlightedGizmo: Gizmo | null = null;

    private _originMouse3D: Vector3 = new Vector3();
    private _previousElementPos: Vector3 = new Vector3();
    private _previousElementRot: Quaternion = new Quaternion();
    private _previousElementScale: Vector3 = new Vector3();

    public constructor() {
        super();
        this._raycaster = new Raycaster();
        this._attachedMesh = null;
        this._origin = new Group();

        this._scaleGizmos = [
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(-1, -1, 1)), new Vector2(-1, -1)), // Bottom left
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(1, -1, 1)), new Vector2(1, -1)), // Bottom right
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(-1, 1, 1)), new Vector2(-1, 1)), // Top left
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(1, 1, 1)), new Vector2(1, 1)), // Top right
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(1, 0, 1)), new Vector2(1, 0)), // Middle right
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(-1, 0, 1)), new Vector2(-1, 0)), // Middle left
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(0, 1, 1)), new Vector2(0, 1)), // Top middle
            new ScaleGizmo((pos) => this.updateScaleGizmo(pos, new Vector3(0, -1, 1)), new Vector2(0, -1)), // Bottom middle
        ];

        this._gizmos = [
            new PositionGizmo((pos) => {
                if (this.attachedMesh && this._attachedMesh?.canMove) {
                    // Set position from difference since origin of transform
                    this._attachedMesh.position.copy(
                        this._previousElementPos.clone().add(pos.sub(this._originMouse3D)),
                    );
                    this.restrictMovement(); // Restrict on sole
                }
            }),
            new RotationGizmo((pos) => {
                if (this.attachedMesh && this._attachedMesh?.canRotate) {
                    this._attachedMesh.quaternion.copy(this._previousElementRot);
                    const wp = new Vector3();
                    this._attachedMesh.getWorldPosition(wp);

                    // Get signed angle between the two vectors by normal plane from center since origin of transform
                    const vA = this._originMouse3D.clone().sub(wp).normalize(); // Vector center to origin mouse
                    const vB = pos.sub(wp).normalize(); // Vector center to current mouse
                    let angle = Math.acos(vA.dot(vB)); // Angle between the two vectors (unsigned)
                    // Transform unsigned angle to signed by normal comparison
                    const cross = vA.cross(vB);
                    const normal = new Vector3(0, 0, 1);
                    if (normal.dot(cross) < 0) {
                        angle = -angle;
                    }
                    this._attachedMesh.rotateZ(angle); // Rotate by this angle (from origin of transform)
                }
            }),
            ...this._scaleGizmos,
        ];
        this._origin.add(...this._gizmos);
    }

    /**
     * Scale the attached mesh with the gizmo (with minimal size)
     * @param pos mouse position
     * @param origin3D origin position of the gizmo on the elementMesh
     */
    private updateScaleGizmo(pos: Vector3, origin3D: Vector3): void {
        if (this.attachedMesh && this._attachedMesh?.canScale) {
            const bbSize = new Vector3();
            this.attachedMesh.boundingBox.getSize(bbSize);
            bbSize.divideScalar(10 * ScanController.SCALE_TO_M_FACTOR);
            bbSize.max(new Vector3(1, 1, 1)); // Prevents division by 0 for flat meshes

            const diffPos = pos
                .sub(this._originMouse3D)
                .applyAxisAngle(new Vector3(0, 0, 1), -this.attachedMesh.rotation.z)
                .multiply(origin3D); // Position difference with rotation compensation

            const finalScale = diffPos.divide(bbSize); // Scale is computed from difference position

            const minBB = new Vector3(1, 1, 1).divide(bbSize); // Minimal bounding box

            // Set scale from origin of transform restricted with minimal bounding box
            this.attachedMesh.scale.copy(
                this._previousElementScale
                    .clone()
                    .add(finalScale.divideScalar(10 * ScanController.SCALE_TO_M_FACTOR))
                    .max(minBB),
            );
            // Adapt z scale
            this.attachedMesh.scale.z = (this.attachedMesh.scale.x + this.attachedMesh.scale.y) / 2;

            // Set position to compensate for new scale (scale is not from center but from borders)
            const finalPos = this.attachedMesh.scale
                .clone()
                .sub(this._previousElementScale)
                .multiply(bbSize)
                .multiplyScalar(0.5)
                .multiply(origin3D)
                .applyAxisAngle(new Vector3(0, 0, 1), this.attachedMesh.rotation.z);

            this.attachedMesh.position.copy(
                this._previousElementPos.clone().add(finalPos.multiplyScalar(10 * ScanController.SCALE_TO_M_FACTOR)),
            );
            this.updateGizmos(); // Update all gizmos to compensate for new scale
        }
    }

    /**
     * Attach gizmos to given element
     * Don't forget to detach gizmos before disposing the element
     * @param element the elementMesh that will take gizmos, or null to detach gizmos
     */
    public updateControls(element: ElementMesh | null): void {
        if (element) {
            this._raycaster.layers.disableAll();
            if (element.canMove) {
                this._raycaster.layers.enable(1);
            }
            if (element.canRotate) {
                this._raycaster.layers.enable(2);
            }
            if (element.canScale) {
                this._raycaster.layers.enable(3);
            }
            // Update gizmos visibility based on rules
            this._gizmos[0].visible = element.canMove;
            this._gizmos[1].visible = element.canRotate;
            this._scaleGizmos.forEach((gizmo) => {
                gizmo.visible = element.canScale;
            });

            element.add(this._origin);
            this._attachedMesh = element;
            this.updateGizmos();
        } else {
            this._attachedMesh?.remove(this._origin);
            this._attachedMesh = null;
        }
    }

    /**
     * Update all gizmos after attached element change
     */
    private updateGizmos(): void {
        if (this._attachedMesh) {
            this._gizmos.forEach((gizmo) => {
                gizmo.update(this._attachedMesh as ElementMesh);
            });
        }
    }

    /**
     * Reset element transformation to previous transforms
     */
    public cancelTransform(): void {
        // Restore transformations
        if (this._attachedMesh) {
            // Restore transformations
            this._attachedMesh.position.copy(this._previousElementPos);
            this._attachedMesh.quaternion.copy(this._previousElementRot);
            this._attachedMesh.scale.copy(this._previousElementScale);
            if (this._highlightedGizmo) {
                this._highlightedGizmo.setHighlighted(false);
                this._highlightedGizmo = null;
            }
            this._pickedGizmo = null;
            this.updateGizmos(); // Adapt gizmos scale
        }
    }

    /**
     * All logic for when the mouse is moved
     * @param mouseX position X on screen of the mouse
     * @param mouseY position Y on screen of the mouse
     */
    public mouseMoveLogic(mouseX: number, mouseY: number): void {
        if (this._pickedGizmo) {
            if (this._attachedMesh) {
                const scanController = ScanController.instance;
                if (!scanController) {
                    throw new Error('ScanController not ready!');
                }
                const mouse3DPos = MeshUtils.mouseToWorld(
                    mouseX,
                    mouseY,
                    scanController.containerSize,
                    scanController.camera,
                    new Vector3(0, 0, 1),
                    scanController.currentSoleMesh.mesh.position.z,
                );
                // Gizmo logic
                this._pickedGizmo.transform(mouse3DPos);
            }
        } else {
            // Highlight gizmo under mouse
            const gizmoUnderMouse = this.getGizmoUnderMouse(mouseX, mouseY);
            if (gizmoUnderMouse !== this._highlightedGizmo) {
                if (this._highlightedGizmo) {
                    this._highlightedGizmo.setHighlighted(false);
                }
                this._highlightedGizmo = gizmoUnderMouse;

                if (this._highlightedGizmo) {
                    this._highlightedGizmo.setHighlighted(true);
                }
            }
        }
    }

    /**
     * All logic for when the mouse is pressed
     * @param mouseX position X on screen of the mouse
     * @param mouseY position Y on screen of the mouse
     * @returns a boolean indicating wether a gizmo was picked or not
     */
    public mouseDownLogic(mouseX: number, mouseY: number): boolean {
        this.mouseMoveLogic(mouseX, mouseY); // Update selected
        if (this._highlightedGizmo) {
            const scanController = ScanController.instance;
            if (!scanController) {
                throw new Error('ScanController not ready!');
            }
            this._originMouse3D = MeshUtils.mouseToWorld(
                mouseX,
                mouseY,
                scanController.containerSize,
                scanController.camera,
                new Vector3(0, 0, 1),
                scanController.currentSoleMesh.mesh.position.z,
            );
            this._pickedGizmo = this._highlightedGizmo;
            if (this._attachedMesh) {
                // Save transforms
                this._attachedMesh.matrix.decompose(
                    this._previousElementPos,
                    this._previousElementRot,
                    this._previousElementScale,
                );
            }
            return true;
        }
        return false;
    }

    /**
     * All logic for when the mouse is released
     */
    public mouseUpLogic(): void {
        // If anything changed, notify it
        if (this._attachedMesh && !get3dHasChanged()) {
            const isElementDirty = !this._attachedMesh.matrix.equals(
                new Matrix4().compose(this._previousElementPos, this._previousElementRot, this._previousElementScale),
            );
            set3dHasChanged(isElementDirty);
        }
        this._pickedGizmo = null;
    }

    /**
     * Dispose all created objects
     */
    public dispose(): void {
        this._gizmos.forEach((gizmo) => {
            gizmo.dispose();
        });
    }

    /**
     * Return the gizmo that is under the mouse
     * @param posX mouse X offset in canvas
     * @param posY mouse Y offset in canvas
     * @returns The gizmo under the mouse, or null
     */
    private getGizmoUnderMouse(posX: number, posY: number): Gizmo | null {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (posX / scanController.containerSize.width) * 2 - 1,
                -(posY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObjects(this._origin.children);
        if (hit.length > 0) {
            let hitObj = hit[0].object;
            // Get Gizmo top parent
            while (!(hitObj instanceof Gizmo) && hitObj.parent) {
                hitObj = hitObj.parent;
            }

            return hitObj as Gizmo;
        }

        return null;
    }

    /**
     * Restrict mesh position to stay inside the parent sole
     */
    private restrictMovement(): void {
        if (this._attachedMesh) {
            // Restrict movement to inside of selected foot bounding box (classic)
            ScanController.instance?.currentSoleMesh.boundingBox.clampPoint(
                this._attachedMesh.position,
                this._attachedMesh.position,
            );

            // Restrict movement to inside of selected foot bounding box (with gap)
            /* const gap = 2;
            const bbox = this._scanController.currentSoleMesh.boundingBox;
            this._attachedMesh.position.x = clamp(this._attachedMesh.position.x, bbox.min.x + gap, bbox.max.x - gap);
            this._attachedMesh.position.y = clamp(this._attachedMesh.position.y, bbox.min.y + gap, bbox.max.y - gap); */

            // Restrict movement to inside of selected foot bounding box (with bounding box)
            /* const gapSize = new Vector3();
            this._attachedMesh.orientedBoundingBox.getSize(gapSize);
            const bbox = this._scanController.currentSoleMesh.boundingBox;
            this._attachedMesh.position.x = clamp(
                this._attachedMesh.position.x,
                bbox.min.x + gapSize.x / 2,
                bbox.max.x - gapSize.x / 2,
            );
            this._attachedMesh.position.y = clamp(
                this._attachedMesh.position.y,
                bbox.min.y + gapSize.y / 2,
                bbox.max.y - gapSize.y / 2,
            ); */
        }
    }
}

export default TransformTool;
