import NavigationMesh from './NavigationMesh';
import OrbitDirection from './OrbitDirections';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import World from '@/3d-app/commons/world/World';
import {
    Raycaster,
    PointLight,
    Vector2,
    Vector3,
    Quaternion,
    Mesh,
    Object3D,
    AmbientLight,
    TextureLoader,
    PlaneGeometry,
    MeshBasicMaterial,
    PerspectiveCamera,
} from 'three';
import type AnimatedCamera from '@/3d-app/commons/camera/AnimatedCamera';

import type TWorldParams from '@/3d-app/commons/world/TWorldParams';

const CAMERA_DISTANCE = 5;

class NavigationController extends World {
    private static _instance: NavigationController;

    public static get instance(): NavigationController {
        if (!NavigationController._instance) {
            throw new Error('NavigationController undefined, please use initialize() method.');
        }
        return NavigationController._instance as NavigationController;
    }

    private _navMesh!: NavigationMesh;

    private _padlockMesh!: Mesh | null;

    private _isNavigationLocked: boolean = false;

    private _raycaster!: Raycaster;

    private _linkedCamera: AnimatedCamera | null = null;

    /**
     * Initialize the NavigationController singleton. Must be initialized before use!
     * @param params world parameters
     */
    public static initialize(params: TWorldParams) {
        if (NavigationController._instance) {
            NavigationController.dispose();
        }
        const instance = new NavigationController(params);

        // Camera parameters
        // instance._camera = new OrthographicCamera(-2, 2, 2, -2, 0.1, 1000); // Uncomment this line for an othographic camera
        instance._camera = new PerspectiveCamera();
        instance._camera.position.z = CAMERA_DISTANCE;

        // Lights
        const cameraLight = new PointLight(0xffffff, 500);
        cameraLight.position.set(0, 0, CAMERA_DISTANCE * 2);
        const ambientLight = new AmbientLight(0xffffff);

        instance._scene.add(ambientLight);
        instance._camera.add(cameraLight);
        instance._scene.add(instance._camera);

        // Load padlock texture
        instance._padlockMesh = null;
        new TextureLoader().load('/images/padlock.png', (texture) => {
            // Create padlock mesh
            const ratio = texture.image.width / texture.image.height;
            instance._padlockMesh = new Mesh(
                new PlaneGeometry(ratio * 2, 2),
                new MeshBasicMaterial({
                    transparent: true,
                    depthTest: false,
                    map: texture,
                }),
            );
            instance._padlockMesh.renderOrder = 100; // Always on top
            instance._padlockMesh.visible = false;
            instance._camera.attach(instance._padlockMesh); // Attach it to camera (will stick to front view)
        });

        // Load the navigation mesh
        instance._navMesh = new NavigationMesh();
        instance._navMesh.loadMesh().then((mesh) => {
            instance._scene.add(mesh);
            instance._renderer.render(instance._scene, instance.camera); // Refresh render
        });

        // Listen to mouse events on canvas
        instance._raycaster = new Raycaster();
        instance._canvas?.addEventListener('mousemove', (event) => instance.onMouseMove(event as MouseEvent));
        instance._canvas?.addEventListener('mouseout', (event) => instance.onMouseMove(event as MouseEvent));
        instance._canvas?.addEventListener('click', (event) => instance.onMouseClick(event as MouseEvent));

        instance.resize();
        NavigationController._instance = instance;
    }

    /**
     * Get wether NavigationController has been initialized
     * @returns true if initialized, false if not
     */
    public static get isInitialized(): boolean {
        return this._instance !== undefined;
    }

    /**
     * Set the camera linked to the NavigationController (will move with the cube)
     * @param linkedCamera the linked camera or null for no camera linked
     */
    public static setLinkedCamera(linkedCamera: AnimatedCamera | null): void {
        const { instance } = NavigationController;
        instance._linkedCamera = linkedCamera;
        if (instance._linkedCamera) {
            NavigationController.updateCameraRotation(instance._linkedCamera.quaternion);
        }
    }

    /**
     * Set the navigation to locked or not.
     * Will prevent cube interactions and change visuals.
     * @param isLocked true for locked, false for unlocked
     */
    public static setNavigationLocked(isLocked: boolean): void {
        const { instance } = NavigationController;
        if (isLocked !== instance._isNavigationLocked) {
            instance._isNavigationLocked = isLocked;
            instance.updateLockMesh();
        }
    }

    /**
     * In case we want to disable the cube immediately after creation, we need to wait until meshes are loaded
     * or the view won't be displayed as locked.
     */
    private updateLockMesh(): void {
        if (this._padlockMesh) {
            if (this._isNavigationLocked) {
                this._padlockMesh.visible = true;
                this._navMesh.setGrayedOut(true);
            } else {
                this._padlockMesh.visible = false;
                this._navMesh.setGrayedOut(false);
            }
            this._renderer.render(this._scene, this.camera); // Refresh render
        } else {
            setTimeout(() => this.updateLockMesh(), 100);
        }
    }

    /**
     * Action on mouse move: color the object under the mouse if there is
     * @param mouseEvent the mouse event
     */
    private onMouseMove(mouseEvent: MouseEvent): void {
        const obj = this.getObjectUnderMouse(mouseEvent.offsetX, mouseEvent.offsetY);
        if (obj) {
            this._navMesh.highlightMesh(obj as Mesh);
        } else {
            this._navMesh.highlightMesh(null);
        }
        this._renderer.render(this._scene, this.camera);
    }

    /**
     * Action on mouse click: start camera movement if raycast hit
     * @param mouseEvent the mouse event
     */
    private onMouseClick(mouseEvent: MouseEvent): void {
        const obj = this.getObjectUnderMouse(mouseEvent.offsetX, mouseEvent.offsetY);
        if (obj) {
            this._linkedCamera?.setCameraOrbit(OrbitDirection.to(obj.name));
        }
    }

    /**
     * Return the object that is under the mouse
     * @param posX mouse X offset in canvas
     * @param posY mouse Y offset in canvas
     * @returns The object under the mouse, or null
     */
    private getObjectUnderMouse(posX: number, posY: number): Object3D | null {
        if (this._isNavigationLocked) {
            return null;
        }
        this._raycaster.setFromCamera(
            new Vector2((posX / this.containerSize.width) * 2 - 1, -(posY / this.containerSize.height) * 2 + 1),
            this._camera,
        );

        const hit = this._raycaster.intersectObject(this._navMesh.mesh);
        if (hit.length > 0) {
            const hitObj = hit[0].object;

            return hitObj;
        }

        return null;
    }

    /**
     * Update camera position to reflect main scene camera orientation
     * @param rotation the main scene camera rotation quaternion
     */
    public static updateCameraRotation(rotation: Quaternion): void {
        const { instance } = NavigationController;
        instance._camera.quaternion.copy(rotation);
        instance._camera.position.copy(new Vector3(0, 0, CAMERA_DISTANCE).applyQuaternion(rotation));

        instance._renderer.render(instance._scene, instance._camera);
    }

    /* eslint-disable class-methods-use-this */
    resize(): void {}

    dispose(): void {
        // Dispose other custom objects
        this._navMesh.dispose();
        if (this._padlockMesh) {
            MeshUtils.disposeObject(this._padlockMesh);
        }
        super.dispose();
    }

    /**
     * Free the allocated resources of the NavigationController, and remove it
     */
    public static dispose(): void {
        NavigationController.instance.dispose();
    }
}

export default NavigationController;
