<script setup lang="ts">
import GrassAnimation from '@/assets/images/grass-animation.json';
import FootprintsAnimation from '@/assets/images/footprints-animation.json';
import CloudSmall from '@/assets/images/cloud-small.png';
import CloudBig from '@/assets/images/cloud-big.png';
import useAdminAnnouncementStore from '@/domains/admin/store/adminAnnouncement.store';
import AdminAnnouncementPageDisplayEnum from '@/domains/admin/typescript/AdminAnnouncementPageDisplayEnum';
import AdminAnnouncementIconEnum from '@/domains/admin/typescript/AdminAnnouncementIconEnum';
import AdminAnnouncementColorEnum from '@/domains/admin/typescript/AdminAnnouncementColorEnum';
import IconWarning from '@/domains/common/components/icons/IconWarning.vue';
import IconCheckRounded from '@/domains/common/components/icons/IconCheckRounded.vue';
import '@lottiefiles/lottie-player';
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';

const adminAnnoucementStore = useAdminAnnouncementStore();

onMounted(() => {
    adminAnnoucementStore.fetchAnnouncementsByPage(AdminAnnouncementPageDisplayEnum.LOGIN);
});

const { getActiveAnnouncements } = storeToRefs(adminAnnoucementStore);

function getIconFromName(iconName: AdminAnnouncementIconEnum) {
    switch (iconName) {
        case AdminAnnouncementIconEnum.INFO:
            return IconWarning;
        case AdminAnnouncementIconEnum.CHECK:
        default:
            return IconCheckRounded;
    }
}

function getColor(color: AdminAnnouncementColorEnum): Record<string, string> {
    switch (color) {
        case AdminAnnouncementColorEnum.DANGER:
            return { background: 'bg-error', text: 'text-error', border: 'border-error' };
        case AdminAnnouncementColorEnum.SUCCESS:
            return { background: 'bg-success', text: 'text-success', border: 'border-success' };
        case AdminAnnouncementColorEnum.WARNING:
            return { class: 'bg-warning', text: 'text-warning', border: 'border-warning' };
        case AdminAnnouncementColorEnum.INFO:
        default:
            return { class: 'bg-blue-500', text: 'text-blue-500', border: 'border-blue-500' };
    }
}
</script>

<template>
    <div class="relative flex min-h-full overflow-hidden">
        <img
            class="absolute inset-0 h-full w-full object-cover"
            src="@/assets/images/login-picture.webp"
            alt="Dessin d'un paysage avec un chemin qui rentre dans une forêt"
        />
        <div
            class="z-20 flex w-5/12 flex-1 flex-col items-center justify-center rounded-br-100 rounded-tr-100 bg-white lg:flex-none"
        >
            <slot />
        </div>
        <div class="relative hidden w-0 flex-1 justify-center lg:flex">
            <div class="bord absolute top-[85px] min-h-[300px] w-full overflow-hidden">
                <img
                    :src="CloudBig"
                    alt="picture of a bid cloud"
                    class="cloud-big absolute h-[100px] w-auto translate-x-[90%]"
                />
                <img
                    :src="CloudSmall"
                    alt="picture of a small cloud"
                    class="cloud-small absolute mt-32 h-[70px] w-auto opacity-0"
                />
            </div>
            <div class="overflow-hidde absolute bottom-0 right-0 z-10 w-[75vw]">
                <lottie-player :src="GrassAnimation" background="transparent" speed="1" loop autoplay />
            </div>
            <div class="absolute bottom-0 left-1/4 w-1/3 overflow-hidden">
                <lottie-player :src="FootprintsAnimation" background="transparent" speed="1" loop autoplay />
            </div>
            <div class="z-40 m-auto my-[10vh] flex w-5/6 flex-col items-center gap-4">
                <div
                    v-for="(announcement, index) in getActiveAnnouncements"
                    :key="index"
                    :class="[
                        getColor(announcement.color).border,
                        'flex w-full flex-col rounded-xl border-2 bg-transparent px-6 py-4',
                    ]"
                >
                    <div :class="['flex h-5 items-center gap-2', getColor(announcement.color).text]">
                        <component
                            :is="getIconFromName(announcement.pictogram)"
                            v-if="getIconFromName(announcement.pictogram)"
                            class="h-full w-5"
                        />
                        <span class="text-xl font-bold uppercase">{{ announcement.title }}</span>
                    </div>
                    <div>
                        <p class="text-justify">{{ announcement.text }}</p>
                    </div>
                </div>
                <img class="w-1/3" src="@/assets/images/logo-couleur.png" alt="Podofit logo" />
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes cloud-from-left-start {
    0% {
        opacity: 1;
        margin-left: -50%;
    }

    98% {
        margin-left: 70%;
        opacity: 1;
    }

    99% {
        opacity: 0;
        margin-left: 71%;
    }

    100% {
        opacity: 0;
        margin-left: -100%;
    }
}

@keyframes cloud-from-left-loop {
    0% {
        margin-left: -100%;
        opacity: 0;
    }

    1% {
        opacity: 1;
        margin-left: -100%;
    }

    99% {
        margin-left: 70%;
        opacity: 1;
    }

    100% {
        opacity: 0;
        margin-left: 71%;
    }
}

@keyframes cloud-from-right-start {
    0% {
        opacity: 1;
        margin-left: 100%;
    }

    99% {
        margin-left: -50%;
        opacity: 1;
    }

    100% {
        opacity: 0;
        margin-left: -55%;
    }
}

@keyframes cloud-from-right-loop {
    0% {
        margin-left: 110%;
        opacity: 0;
    }

    1% {
        opacity: 1;
        margin-left: 110%;
    }

    99% {
        margin-left: -50%;
        opacity: 1;
    }

    100% {
        opacity: 0;
        margin-left: -55%;
    }
}

.cloud-big {
    animation:
        cloud-from-left-start 90s 0s 1 linear,
        cloud-from-left-loop 100s linear infinite 90s;
}

.cloud-small {
    animation:
        cloud-from-right-start 100s 0s 1 linear,
        cloud-from-right-loop 130s linear infinite 100s;
}
</style>
