import useScanStore from '@/domains/scan/store/scan.store';
import useOrderStore from '@/domains/order/store/order.store';
import FootSideEnum from '@/domains/scan/typescript/enums/FootSideEnum';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import type SoleConfigurationInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationInterface';
import type SoleParametersInterface from '@/domains/scan/typescript/interfaces/SoleParametersInterface';
import type { OrderInterface } from '@/domains/order/typescript/OrderInterface';

function setSelectedFoot(isRightFoot: boolean) {
    const scanStore = useScanStore();
    const { selectedFoot } = storeToRefs(scanStore);

    selectedFoot.value = isRightFoot ? FootSideEnum.Right : FootSideEnum.Left;
}

function getSelectedFoot(): boolean {
    const scanStore = useScanStore();
    const { selectedFoot } = storeToRefs(scanStore);
    return selectedFoot.value === FootSideEnum.Right;
}

function setSelected3DItem(newFootSide: number) {
    const scanStore = useScanStore();
    const { selected3DItemId } = storeToRefs(scanStore);

    selected3DItemId.value = newFootSide;
}

function getFormsParameters(): {
    currentOrder: Ref<OrderInterface>;
    soleConfiguration: Ref<SoleConfigurationInterface>;
    soleParametersLeft: Ref<SoleParametersInterface>;
    soleParametersRight: Ref<SoleParametersInterface>;
} {
    const scanStore = useScanStore();
    const orderStore = useOrderStore();
    const { soleParametersLeft, soleParametersRight } = storeToRefs(scanStore);
    const { currentOrder, soleConfiguration } = storeToRefs(orderStore);
    return {
        currentOrder,
        soleConfiguration,
        soleParametersLeft,
        soleParametersRight,
    };
}

function get3dHasChanged() {
    const scanStore = useScanStore();
    const { has3DChanged } = storeToRefs(scanStore);

    return has3DChanged.value;
}

function set3dHasChanged(value: boolean) {
    const scanStore = useScanStore();
    const { has3DChanged } = storeToRefs(scanStore);

    has3DChanged.value = value;
}

export { setSelectedFoot, setSelected3DItem, getFormsParameters, getSelectedFoot, get3dHasChanged, set3dHasChanged };
