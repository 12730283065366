import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';
import StepsNameEnum from '@/domains/scan/typescript/enums/StepsNameEnum';
import BevelOptionsEnum from '@/domains/scan/typescript/enums/BevelOptionsEnum';
import soleApi from '@/domains/scan/api/sole.api';
import scanApi from '@/domains/scan/api/scan.api';
import buildCorrectionParameters from '@/domains/scan/services/buildCorrectionParameters';
import { isStringContained } from '@/utils/strings';
import FootSideEnum from '@/domains/scan/typescript/enums/FootSideEnum';
import scanAPI from '@/3d-vue-api/scanAPI';
import digestMessage from '@/domains/common/services/utils/digestMessage';
import useOrderStore from '@/domains/order/store/order.store';
import formInformationValidation from '@/domains/scan/validators/soleConfiguration.schema';
import { manufactureToString } from '@/domains/order/services/manufactureConverter';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import type ModeEnum from '@/3d-app/scan/ModeEnum';
import type SoleParametersInterface from '@/domains/scan/typescript/interfaces/SoleParametersInterface';
import type SoleCorrectionsInterface from '@/domains/scan/typescript/interfaces/SoleCorrectionsInterface';
import type SoleConfigurationElementInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementInterface';
import type SoleConfigurationElementCategoryInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementCategoryInterface';
import type SoleConfigurationElementCategoryListInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementCategoryListInterface';
import type ScanCorrectionElementsType from '@/domains/scan/typescript/types/ScanCorrectionElementsType';
import type DeviceInterface from '@/domains/scan/typescript/interfaces/DeviceInterface';
import type OrderPresetInterface from '@/domains/order/typescript/OrderPresetInterface';

export default defineStore('scan', () => {
    const {
        SoleThickness,
        GapFirstHead,
        GapFifthHead,
        InterArchPoint,
        InterArchPointConcavity,
        InternalArch,
        InternalArchSummitsPosition,
        ExternalArch,
        ExternalArchSummitsPosition,
        AddedThicknessHeel,
        MedialCupolaHeight,
        LateralCupolaHeight,
        FlatnessHeel,
        HeelWidening,
        MidfootWidening,
        MetatarsalWidening,
        TotalPatternWidening,
        HeelBevelHeight,
        FrontBevelHeight,
        FullLengthBevelHeight,
    } = buildCorrectionParameters();

    const defaultSoleParameters: SoleParametersInterface = {
        soleThickness: { ...SoleThickness.default }.id,
        thickerFrontFoot: false,
        gapFirstHead: { ...GapFirstHead.default }.id,
        gapFifthHead: { ...GapFifthHead.default }.id,
        interArchPoint: { ...InterArchPoint.default }.id,
        interArchPointConcavity: parseInt(InterArchPointConcavity.default.name, 10),
        internalArch: { ...InternalArch.default }.id,
        internalArchSummitsPosition: parseInt(InternalArchSummitsPosition.default.name, 10),
        externalArch: { ...ExternalArch.default }.id,
        externalArchSummitsPosition: parseInt(ExternalArchSummitsPosition.default.name, 10),
        addedThicknessHeel: { ...AddedThicknessHeel.default }.id,
        medialCupolaHeight: { ...MedialCupolaHeight.default }.id,
        lateralCupolaHeight: { ...LateralCupolaHeight.default }.id,
        flatnessHeel: parseInt(FlatnessHeel.default.name, 10),
    };

    const defaultSoleCorrections: SoleCorrectionsInterface = {
        heelWidening: { ...HeelWidening.default }.id,
        midfootWidening: { ...MidfootWidening.default }.id,
        metatarsalWidening: { ...MetatarsalWidening.default }.id,
        totalPatternWidening: { ...TotalPatternWidening.default }.id,
        heelBevel: BevelOptionsEnum.No,
        heelBevelHeight: { ...HeelBevelHeight.default }.id,
        frontFootBevel: BevelOptionsEnum.No,
        frontFootBevelHeight: { ...FrontBevelHeight.default }.id,
        fullLengthBevel: BevelOptionsEnum.No,
        fullLengthBevelHeight: { ...FullLengthBevelHeight.default }.id,
    };

    const currentMode = ref<ModeEnum>();

    const scanSliderValue = ref<number>(80);
    const soleSliderValue = ref<number>(80);

    const element3dSliderValue = ref<number>(100);
    const holling3dSliderValue = ref<number>(100);
    const engravingSliderValue = ref<number>(100);
    const morphingSliderValue = ref<number>(100);

    const isSoleComputing = ref(false);
    const hasSizeChanged = ref(false);
    const has3DChanged = ref(false);

    const { currentOrder, soleConfiguration } = storeToRefs(useOrderStore());

    const currentStep = ref<StepsNameEnum>(StepsNameEnum.Scanning);

    const soleParametersLeft = ref<SoleParametersInterface>({
        ...defaultSoleParameters,
    });
    const soleParametersRight = ref<SoleParametersInterface>({
        ...defaultSoleParameters,
    });

    const currentFootSoleParameters = computed<SoleParametersInterface>(() => {
        return selectedFoot.value === FootSideEnum.Left ? soleParametersLeft.value : soleParametersRight.value;
    });

    const soleCorrectionsLeft = ref<SoleCorrectionsInterface>({
        ...defaultSoleCorrections,
    });
    const soleCorrectionsRight = ref<SoleCorrectionsInterface>({
        ...defaultSoleCorrections,
    });

    const currentFootSoleCorrection = computed<SoleCorrectionsInterface>(() => {
        return selectedFoot.value === FootSideEnum.Left ? soleCorrectionsLeft.value : soleCorrectionsRight.value;
    });

    const catalogElements = ref<Array<SoleConfigurationElementInterface>>();

    const filteredCategoriesWithElements = ref<Array<SoleConfigurationElementCategoryListInterface>>();

    const scanCorrectionElementList = ref<ScanCorrectionElementsType[]>([]);

    const fetchCatalogElements = async () => {
        catalogElements.value = await soleApi.getElements();
    };

    const elementCategories = computed<Array<SoleConfigurationElementCategoryInterface> | undefined>(() => {
        if (catalogElements.value === undefined) {
            return undefined;
        }

        const elementsCategories: Array<SoleConfigurationElementCategoryInterface> = catalogElements.value.map(
            (element) => element.category,
        );

        return elementsCategories.reduce((uniqueCategories, value) => {
            const categoryExists = uniqueCategories.some((cat) => cat.code === value.code);
            if (!categoryExists) {
                return [...uniqueCategories, value];
            }
            return uniqueCategories;
        }, [] as Array<SoleConfigurationElementCategoryInterface>);
    });

    const catalogElementsByManufacture = computed<Array<SoleConfigurationElementInterface> | undefined>(() => {
        if (catalogElements.value === undefined) {
            return undefined;
        }

        return catalogElements.value.filter(
            (element) =>
                currentOrder.value.manufacture === null ||
                element.manufacturing === manufactureToString(currentOrder.value.manufacture) ||
                element.manufacturing === ManufactureTypeEnum.All,
        );
    });

    const allCategoriesWithElements = computed<Array<SoleConfigurationElementCategoryListInterface> | undefined>(() => {
        if (catalogElements.value === undefined) {
            return undefined;
        }

        if (elementCategories.value === undefined) {
            return undefined;
        }

        const elementsByCategory: Array<SoleConfigurationElementCategoryListInterface> = elementCategories.value.map(
            (category) => {
                const elements = catalogElements.value!.filter((element) => {
                    return element.category.code === category.code;
                });

                return { ...category, elements };
            },
        );

        return elementsByCategory;
    });

    const selectedElementCategoryCode = computed<string>(() => {
        if (selected3DItemId.value > 0) {
            const elementId = scanCorrectionElementList.value.find(
                (element) => element.sceneId === selected3DItemId.value,
            )?.elementId;
            if (elementId) {
                return (
                    catalogElements.value?.find((catalogElement) => catalogElement.id === elementId)?.category.code ||
                    ''
                );
            }
        }
        return '';
    });

    const filterElementsInCategoriesBySearchTerm = (searchTerm?: string) => {
        if (!searchTerm) {
            filteredCategoriesWithElements.value = allCategoriesWithElements.value;
        } else {
            const res = allCategoriesWithElements.value
                ?.map((cat) => {
                    return {
                        ...cat,
                        elements: cat.elements.filter((el) => {
                            const hasTag = el.tags.some((t) => isStringContained(searchTerm, t.name));
                            const hasName = isStringContained(searchTerm, el.name);
                            return hasTag || hasName;
                        }),
                    };
                })
                .filter((cat) => cat.elements.length);

            filteredCategoriesWithElements.value = res;
        }
    };
    const selectedFoot = ref<FootSideEnum>(FootSideEnum.Left);

    watch(
        () => catalogElements.value && elementCategories.value,
        () => {
            filterElementsInCategoriesBySearchTerm();
        },
    );
    watch(
        () => selectedFoot.value,
        () => {
            scanAPI.setCurrentFoot(selectedFoot.value);
        },
    );

    const { errors, validate, setValues } = useForm({
        validationSchema: formInformationValidation,
    });

    const validateSoleConfiguration = () => {
        const { id, patient, consultant, creationDate, number, status, ...orderRest } = currentOrder.value; // eslint-disable-line @typescript-eslint/no-unused-vars
        setValues({ ...orderRest, ...soleConfiguration.value });
        validate();
    };

    const selected3DItemId = ref<number>(-1);

    // Manage scan hash

    const scanHash = ref<string>();
    const lastComputeScanHash = ref<string>();

    function setScanHash(hash: string) {
        scanHash.value = hash;
    }

    function setLastComputedScanHash(hash: string) {
        lastComputeScanHash.value = hash;
    }

    const scanSettingsAsString = computed<string>(() => {
        const orderParameters = {
            size: currentOrder.value.size,
            glued: currentOrder.value.glued,
            lowCut: currentOrder.value.lowCut,
            sanded: currentOrder.value.sanded,
            cuttingLength: currentOrder.value.cuttingLength,
            manufacture: currentOrder.value.manufacture,
            material: currentOrder.value.material,
            coveringMaterial: currentOrder.value.coveringMaterial,
        };

        const elementsParameters = scanCorrectionElementList.value.map((element) => {
            return {
                isRightFoot: element.isRightFoot,
                surfaceType: element.surfaceType,
                thickness: element.thickness,
            };
        });

        const soleConfigurationString = JSON.stringify({ ...orderParameters, ...soleConfiguration.value });
        const scanCorrectionElementListString = JSON.stringify(elementsParameters);
        const soleParametersLeftString = JSON.stringify(soleParametersLeft.value);
        const soleParametersRightString = JSON.stringify(soleParametersRight.value);
        const soleCorrectionsLeftString = JSON.stringify(soleCorrectionsLeft.value);
        const soleCorrectionsRightString = JSON.stringify(soleCorrectionsRight.value);

        return `${soleConfigurationString}${scanCorrectionElementListString}${soleParametersLeftString}${soleParametersRightString}${soleCorrectionsLeftString}${soleCorrectionsRightString}`;
    });

    const shouldComputeSoles = computed<boolean>(() => {
        return has3DChanged.value || scanHash.value !== lastComputeScanHash.value;
    });

    function updateLastScanHash() {
        lastComputeScanHash.value = scanHash.value;
    }

    async function generateHash() {
        return digestMessage(scanSettingsAsString.value);
    }

    const isScanDigestEnabled = ref<boolean>(true);

    watch(
        () => scanSettingsAsString.value,
        async () => {
            if (isScanDigestEnabled.value) {
                scanHash.value = await generateHash();
            }
        },
    );

    // Manage sole sync and presets

    const synchronizeSolParameters = (footSide: FootSideEnum) => {
        if (footSide === FootSideEnum.Left) {
            soleParametersRight.value = {
                ...soleParametersLeft.value,
            };
        } else {
            soleParametersLeft.value = {
                ...soleParametersRight.value,
            };
        }
    };

    const synchronizeSolCorrections = (footSide: FootSideEnum) => {
        if (footSide === FootSideEnum.Left) {
            soleCorrectionsRight.value = {
                ...soleCorrectionsLeft.value,
            };
        } else {
            soleCorrectionsLeft.value = {
                ...soleCorrectionsRight.value,
            };
        }
    };

    const loadSoleCorrections = (
        leftfoot: SoleCorrectionsInterface | null,
        rightfoot: SoleCorrectionsInterface | null,
    ) => {
        if (leftfoot) {
            soleCorrectionsLeft.value = leftfoot;
        }
        if (rightfoot) {
            soleCorrectionsRight.value = rightfoot;
        }
    };

    const loadSoleParameters = (
        leftfoot: SoleParametersInterface | null,
        rightfoot: SoleParametersInterface | null,
    ) => {
        if (leftfoot) {
            soleParametersLeft.value = leftfoot;
        }
        if (rightfoot) {
            soleParametersRight.value = rightfoot;
        }
    };

    const applyPreset = (preset: OrderPresetInterface) => {
        soleParametersLeft.value = {
            ...soleParametersLeft.value,
            ...preset?.soleParameters,
        };
        soleParametersRight.value = {
            ...soleParametersRight.value,
            ...preset?.soleParameters,
        };

        soleCorrectionsLeft.value = {
            ...soleCorrectionsLeft.value,
            ...preset?.soleCorrections,
        };
        soleCorrectionsRight.value = {
            ...soleCorrectionsRight.value,
            ...preset?.soleCorrections,
        };
    };

    const deviceList = ref<DeviceInterface[]>([]);

    async function fetchDevices() {
        deviceList.value = await scanApi.getListDevices();
    }

    function $reset() {
        console.info('reset scan store');
        soleParametersLeft.value = {
            ...defaultSoleParameters,
        };
        soleParametersRight.value = {
            ...defaultSoleParameters,
        };
        soleCorrectionsLeft.value = {
            ...defaultSoleCorrections,
        };
        soleCorrectionsRight.value = {
            ...defaultSoleCorrections,
        };
        scanCorrectionElementList.value = [];
        selected3DItemId.value = -1;

        currentStep.value = StepsNameEnum.Scanning;
        catalogElements.value = undefined;
        filteredCategoriesWithElements.value = undefined;
        scanHash.value = undefined;
        lastComputeScanHash.value = undefined;
        isScanDigestEnabled.value = true;
        selectedFoot.value = FootSideEnum.Left;
        currentMode.value = undefined;
        hasSizeChanged.value = false;
    }

    return {
        currentStep,
        soleParametersLeft,
        soleParametersRight,
        currentFootSoleParameters,
        soleCorrectionsLeft,
        soleCorrectionsRight,
        currentFootSoleCorrection,
        catalogElements,
        catalogElementsByManufacture,
        fetchCatalogElements,
        filteredCategoriesWithElements,
        filterElementsInCategoriesBySearchTerm,
        soleCorrectionsErrors: errors,
        validateSoleConfiguration,
        scanCorrectionElementList,
        selectedFoot,
        selected3DItemId,
        synchronizeSolParameters,
        synchronizeSolCorrections,
        selectedElementCategoryCode,
        allCategoriesWithElements,
        loadSoleCorrections,
        loadSoleParameters,
        applyPreset,

        shouldComputeSoles,
        updateLastScanHash,
        scanHash,
        setScanHash,
        lastComputeScanHash,
        setLastComputedScanHash,
        isScanDigestEnabled,
        generateHash,
        isSoleComputing,
        currentMode,
        hasSizeChanged,
        has3DChanged,

        deviceList,
        fetchDevices,
        scanSliderValue,
        soleSliderValue,
        element3dSliderValue,
        holling3dSliderValue,
        engravingSliderValue,
        morphingSliderValue,

        $reset,
    };
});
