<script setup lang="ts">
import router from '@/router';
import RouteNameEnum from '@/router/routeNameEnum';
import { isParamString } from '@/router/routeParamType';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import BaseErrorMessage from '@/domains/common/components/BaseErrorMessage.vue';
import BaseInput from '@/domains/common/components/BaseInput.vue';
import BaseInputPassword from '@/domains/common/components/BaseInputPassword.vue';
import BaseInputLabel from '@/domains/common/components/BaseInputLabel.vue';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import registrationSchema from '@/domains/authentication/validators/registration.schema';
import invitationApi from '@/domains/office/api/invitation.api';

import { computed, ref } from 'vue';
import { useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
import type RegistrationFormInterface from '@/domains/authentication/typescript/RegistrationFormInterface';

const route = useRoute();

const { values, errors, handleSubmit } = useForm<RegistrationFormInterface>({
    validationSchema: registrationSchema,
    initialValues: {
        firstname: '',
        lastname: '',
        password: '',
    },
});

const isCompromised = ref(false);

const isFormValid = computed(() => Object.keys(errors.value).length === 0);

defineEmits(['cancel']);

const registerForm = async () => {
    try {
        const token = isParamString(route.params.token) ? route.params.token : '';
        const registerData = { ...values, token };
        await invitationApi.acceptInvitation(registerData.token, registerData);
        router.push({ name: RouteNameEnum.Login });
    } catch (error) {
        isCompromised.value = true;
        throw new Error(`Error on save data: ${error}`);
    }
};

function onSubmit(event: Event) {
    handleSubmit(registerForm)(event);
}
</script>

<template>
    <form class="flex flex-col gap-2" @submit.prevent="onSubmit">
        <div>
            <BaseInputLabel target="lastname">{{ $t('invitation.form_register.label_lastname') }}</BaseInputLabel>
            <BaseInput
                id="lastname"
                type="text"
                placeholder="Ex: Dupont"
                class="text-input"
                :color="ColorEnum.Secondary"
                :name="$t('invitation.form_register.label_lastname')"
            />
            <BaseErrorMessage field-name="lastname" />
        </div>
        <div>
            <BaseInputLabel target="firstname">{{ $t('invitation.form_register.label_firstname') }}</BaseInputLabel>
            <BaseInput
                id="firstname"
                type="text"
                placeholder="Ex: Jean"
                class="text-input"
                :color="ColorEnum.Secondary"
                :name="$t('invitation.form_register.label_firstname')"
            />
            <BaseErrorMessage field-name="firstname" />
        </div>
        <div class="flex flex-col">
            <BaseInputLabel target="password">{{ $t('invitation.form_register.label_password') }}</BaseInputLabel>
            <BaseInputPassword
                id="password"
                class="text-input"
                autocomplete="password"
                :color="ColorEnum.Secondary"
                :name="$t('invitation.form_register.label_password')"
                :display-strength="true"
            />
            <p v-if="isCompromised" class="text-sm text-red-400">{{ $t('login.compromised') }}</p>
            <BaseErrorMessage field-name="password" />
        </div>
        <div>
            <BaseInputLabel target="confirm_password">{{
                $t('invitation.form_register.label_confirm_password')
            }}</BaseInputLabel>
            <BaseInputPassword
                id="confirm_password"
                class="text-input"
                autocomplete="password"
                :color="ColorEnum.Secondary"
                :name="$t('invitation.form_register.label_confirm_password')"
            />
            <BaseErrorMessage field-name="confirm_password" />
        </div>
        <div class="mt-10 flex gap-2.5">
            <BaseButton
                class="w-1/2"
                :color="ButtonColorEnum.Secondary"
                :label="$t('global.cancel')"
                @click="$emit('cancel')"
            />
            <BaseButton
                class="w-1/2"
                :disabled="!isFormValid"
                :color="ButtonColorEnum.Primary"
                :label="$t('invitation.form_register.create_account')"
                type="submit"
            />
        </div>
    </form>
</template>
